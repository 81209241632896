import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  FrontError,
  InputItem,
  Select
} from '../../components/Auth/Auth.styles'
import { gql, useLazyQuery } from '@apollo/client'

const PROVINCIAS = gql`
  query GetProvincias($DepCode: String) {
    GetProvincias(DepCode: $DepCode) {
      ProvCodi
      ProvNom
      UbiProCodi
    }
  }
`

const ProvinciaSelect = ({ state, hOnChange }) => {
  const [provincias, setProvincias] = useState([])
  const [GetProvincias, { loading }] = useLazyQuery(PROVINCIAS, {
    onCompleted: (data) => setProvincias(data.GetProvincias)
  })

  useEffect(() => {
    GetProvincias({ variables: { DepCode: state.DeparCodi } })
  }, [state.DeparCodi])

  return (
    <InputItem>
      <Select
        name="ProvCodi"
        value={state.ProvCodi}
        onChange={hOnChange}
        disabled={loading}
      >
        <option value="">PROVINCIA</option>
        {provincias.map(({ ProvCodi, ProvNom }) => (
          <option key={ProvCodi} value={ProvCodi}>
            {ProvNom}
          </option>
        ))}
      </Select>
      <FrontError></FrontError>
    </InputItem>
  )
}

ProvinciaSelect.propTypes = {
  state: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired
}

export default ProvinciaSelect

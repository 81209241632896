import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import {
  BtnWrap,
  InfoWrap,
  DetailsRow,
  DetailsTitle,
  DetailsContainer
} from '../../components/Details/Detail.styles'
import TableDetails from './TableDetails'
import AccToTranfer from './AccToTranfer'
import InputText from '../../components/Inputs/InputText'
import { Headline } from '../../components/Auth/Auth.styles'
import { AppContainer } from '../../components/App/App.styles'
import { Button } from '../../components/Buttons/Buttons.styles'
import AppContext from '../../context/App/AppContext'

import useForm from '../../hooks/useForm'
import { codOperationValid } from '../../helpers/formValidation'

import {
  GET_TRANSACTIONS_BYPROFILE,
  NEW_TRANSACTION
} from '../../apollo/queries'

const DetailsTransaction = ({ history, location }) => {
  const { selectedProfile } = useContext(AppContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [formValues, hOnChange] = useForm({
    ...location.state,
    nroTransaccion: ''
  })

  const [newTransaction] = useMutation(NEW_TRANSACTION)

  useEffect(() => {
    if (isDirty) {
      const validation = codOperationValid(formValues)
      setErrors(validation)
    }
  }, [formValues])

  const hOnSubmit = async (e) => {
    e.preventDefault()

    const validation = codOperationValid(formValues)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      const {
        cupon,
        createdAt,
        cuentaAdmin,
        amountToPay,
        amountToBuy,
        exchangeRate,
        origenFondos,
        cuentaPerfil,
        ...rest
      } = location.state
      const payload = {
        ...rest,
        origenFondos: parseInt(origenFondos),
        nroTransaccion: formValues.nroTransaccion,
        cuentaAdmId: parseInt(cuentaAdmin.cuentaAdmId),
        cantidadMonedaCambiar: parseFloat(amountToPay),
        cuentaPerfilId: parseInt(cuentaPerfil.cuentaPerfilId)
      }

      console.log(payload)

      try {
        const GQLData = await newTransaction({
          variables: { input: payload },
          refetchQueries: [
            {
              query: GET_TRANSACTIONS_BYPROFILE,
              variables: {
                page: 1,
                estado: 0,
                number_paginate: 50,
                perfilId: selectedProfile.perfilId
              }
            }
          ]
        })

        const transaction = GQLData.data.CrearTransaccion

        history.push('/app/transaction/success', {
          ...payload,
          ...transaction
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const hGoBack = () => {
    // history.push('/app/transaction/new', formValues)
    history.goBack()
  }

  return (
    <AppContainer>
      <Headline expand>Detalles de la transaccion</Headline>
      <InfoWrap>
        <TableDetails detailsValues={formValues} />
        <AccToTranfer adminBank={location.state.cuentaAdmin.cuentaAdmId} />
        <DetailsContainer>
          <DetailsRow title="true">
            <DetailsTitle>
              Para confirmar la transaccion ingresa el codigo de operacion.
            </DetailsTitle>
          </DetailsRow>
          <DetailsRow>
            <InputText
              name="nroTransaccion"
              type="email"
              state={formValues}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Cod. Operacion"
            />
          </DetailsRow>
        </DetailsContainer>
      </InfoWrap>
      <BtnWrap>
        <Button onClick={hGoBack}>Atras</Button>
        <Button onClick={hOnSubmit}>Continuar</Button>
      </BtnWrap>
    </AppContainer>
  )
}

DetailsTransaction.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default DetailsTransaction

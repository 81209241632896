import React from 'react'
import PropTypes from 'prop-types'
import {
  DetailsContainer,
  DetailsRow,
  DetailsSup,
  DetailsTable,
  DetailsTitle,
  TextBold,
  TextMuted
} from '../../components/Details/Detail.styles'

import { banks } from '../fakeData'
import { exchangeRateFormat, numberFormat } from '../../helpers/numberFormat'

const TableDetails = ({ detailsValues }) => {
  const {
    cupon,
    createdAt,
    cuentaAdmin,
    amountToBuy,
    amountToPay,
    exchangeRate,
    tipoTransaccion,
    cuentaPerfil
  } = detailsValues

  const isPayWPEN = tipoTransaccion === 2
  const bank = banks[parseInt(cuentaPerfil.banco) + 1]
  const hasCupon = Object.keys(cupon).length !== 0
  const dateFormated = new Date(createdAt).toLocaleDateString()

  return (
    <DetailsContainer>
      <DetailsTable>
        <DetailsRow title="true">
          <DetailsTitle>Estado</DetailsTitle>
          <DetailsSup>
            <p>En proceso</p>
          </DetailsSup>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Orden Creada</TextMuted>
          <TextBold>{dateFormated}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Cuenta de destino</TextMuted>
          <TextBold>{cuentaPerfil.nroCuenta}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Banco de Origen</TextMuted>
          <TextBold>{cuentaAdmin.nombre}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Banco de Destino</TextMuted>
          <TextBold>{bank.bankAbrr}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Monto a pagar</TextMuted>
          <TextBold>{numberFormat(amountToPay, isPayWPEN)}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Monto a recibir</TextMuted>
          <TextBold>{numberFormat(amountToBuy, !isPayWPEN)}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Tipo de cambio</TextMuted>
          <TextBold>{exchangeRateFormat(exchangeRate)}</TextBold>
        </DetailsRow>
        {hasCupon && (
          <>
            <DetailsRow>
              <TextMuted>Cupon</TextMuted>
              <TextBold>{cupon.codigo}</TextBold>
            </DetailsRow>
            <DetailsRow>
              <TextMuted>Monto aplicado</TextMuted>
              <TextBold>{cupon.monto}</TextBold>
            </DetailsRow>
          </>
        )}
      </DetailsTable>
    </DetailsContainer>
  )
}

TableDetails.propTypes = {
  detailsValues: PropTypes.object.isRequired
}

export default TableDetails

import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/Auth/AuthContext'
import PropTypes from 'prop-types'

import {
  Form,
  AuthLink,
  Headline,
  LinkItems,
  Container,
  InputsWrapper,
  SelectWrapper,
  BackError
} from '../../components/Auth/Auth.styles'

import { Button } from '../../components/Buttons'
import InputPass from '../../components/Inputs/InputPass'
import InputText from '../../components/Inputs/InputText'
import InputSelect from '../../components/Inputs/InputSelect'

import useForm from '../../hooks/useForm'
import { regValid } from '../../helpers/formValidation'
import { useMutation } from '@apollo/client'
import { CREATE_USER } from '../../apollo/queries'
import { backendErrors } from '../../helpers/backenkErrors'
import { FaTimesCircle } from 'react-icons/fa'

const RegisterPage = ({ history }) => {
  const { setUserAction, setRegAction } = useContext(AuthContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange] = useForm({
    celular: '',
    email: '',
    email2: '',
    password: '',
    password2: '',
    nroDocumento: '',
    tipoDocumento: '0',
    codigoRefInvitado: ''
  })

  const [createUser] = useMutation(CREATE_USER)

  useEffect(() => {
    if (isDirty) {
      const validation = regValid(state)
      setErrors(validation)
    }
  }, [state])

  const hOnSubmit = async (e) => {
    e.preventDefault()

    setIsDirty(true)
    const validation = regValid(state)
    if (!validation.ok) return setErrors(validation)

    const payload = {
      estado: 1,
      tipoInicio: 1,
      tipoUsuario: 2,
      suscripcion: 1,
      email: state.email,
      password: state.password,
      codigoRefInvitado: state.codigoRefInvitado
    }

    try {
      const GQLData = await createUser({
        variables: { input: payload }
      })
      // email
      // userId
      // apiToken
      setUserAction(GQLData.data.CrearUsuario)
      setRegAction({
        celular: state.celular,
        nroDocumento: state.nroDocumento,
        tipoDocumento: state.tipoDocumento
      })
      history.push('/auth/register/personalInfo')
    } catch ({ graphQLErrors }) {
      graphQLErrors.forEach((err) => {
        const errorsObj = backendErrors(err.debugMessage)
        setErrors(errorsObj)
        console.info(`Error: ${errorsObj.backendErr.msg}`)
      })
    }
  }

  return (
    <Container size="large" center>
      <Headline expand>Registro</Headline>
      <BackError>
        {errors.backendErr && (
          <>
            <FaTimesCircle />
            {errors.backendErr?.msg}
          </>
        )}
      </BackError>
      <Form onSubmit={hOnSubmit}>
        <InputsWrapper>
          <InputText
            name="email"
            type="email"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Correo"
          />
          <InputText
            name="email2"
            type="email"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Confirmar Correo"
          />
          <SelectWrapper>
            <InputPass
              name="password"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Contraseña"
            />
            <InputPass
              name="password2"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Confirme contraseña"
            />
          </SelectWrapper>
          <SelectWrapper>
            <InputSelect
              name="tipoDocumento"
              state={state}
              hOnChange={hOnChange}
            />
            <InputText
              name="nroDocumento"
              type="number"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Documento"
            />
          </SelectWrapper>
          <SelectWrapper>
            <InputText
              name="celular"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Telefono"
            />
            <InputText
              name="codigoRefInvitado"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="COD Referido (OPCIONAL)"
            />
          </SelectWrapper>
        </InputsWrapper>
        <Button type="submit">Siguiente</Button>
        <LinkItems>
          <AuthLink to="/auth/login">¿ya posees una cuenta?</AuthLink>
        </LinkItems>
      </Form>
    </Container>
  )
}

RegisterPage.propTypes = {
  history: PropTypes.object
}

export default RegisterPage

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FaBars } from 'react-icons/fa'
import { useLocation } from 'react-router'

import {
  Nav,
  NavMenu,
  NavWrapper,
  NavMenuItem,
  NavContainer,
  NavMobileIcon
} from './Navbar.styles'
import NavbarUser from './NavbarUser'
import logo from '../../assets/logo.png'

import AppContext from '../../context/App/AppContext'
// import AuthContext from '../../context/Auth/AuthContext'

const Navbar = ({ setIsOpen }) => {
  const { pathname } = useLocation()
  // const { logoutAction } = useContext(AuthContext)
  const { selectedProfile } = useContext(AppContext)

  const hOpen = () => setIsOpen(true)

  const isAuth = Object.keys(selectedProfile).length !== 0

  // const handleLogout = () => {
  //   purgeProfile()
  //   logoutAction()
  // }

  return (
    <Nav>
      <NavWrapper>
        <NavContainer isAuth={isAuth}>
          <img src={logo} alt="Logo Kambista" />
          <NavMenu>
            {isAuth && (
              <>
                <NavMenuItem to="/app/transaction/new" pathname={pathname}>
                  Nueva transacción
                </NavMenuItem>
                <NavMenuItem to="/app/bankAccounts" pathname={pathname}>
                  Cuentas
                </NavMenuItem>
                <NavMenuItem to="/app/transaction/records" pathname={pathname}>
                  Historial
                </NavMenuItem>
              </>
            )}
          </NavMenu>

          {isAuth && <NavbarUser name={selectedProfile.name} />}

          {isAuth && (
            <NavMobileIcon onClick={hOpen}>
              <FaBars />
            </NavMobileIcon>
          )}
        </NavContainer>
      </NavWrapper>
    </Nav>
  )
}

Navbar.propTypes = {
  setIsOpen: PropTypes.func.isRequired
}

export default Navbar

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Select,
  Headline,
  Container,
  FullWidth,
  FrontError,
  OcupationBtnWrap,
  OcupationContainer
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons'

import { ocupations } from '../fakeData'
import useForm from '../../hooks/useForm'
import NewPContext from '../../context/NewProfile/NewPContext'
import { ocupationValid } from '../../helpers/formValidation'
// import { ocupationValid } from '../../helpers/formValidation'

const NewPersonOcupation = ({ history }) => {
  const { setPersonProfile } = useContext(NewPContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange, setState] = useForm({
    ocupacion: '',
    personaExpuesta: null
  })

  useEffect(() => {
    if (isDirty) {
      const validation = ocupationValid(state)
      setErrors(validation)
    }
  }, [state])

  const hPEP = (number) => {
    setState({
      ...state,
      personaExpuesta: number
    })
  }

  const hOnSubmit = (e) => {
    e.preventDefault()

    const validation = ocupationValid(state)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      setPersonProfile(state)
      history.push('/app/profile/personVerification')
    }
  }

  return (
    <Container size="large" center>
      <Headline expand>Ocupacion</Headline>
      <Form onSubmit={hOnSubmit}>
        <FullWidth>
          <Select name="ocupacion" value={state.ocupacion} onChange={hOnChange}>
            <option value="">Seleccione una ocupación</option>
            {ocupations.map(({ id, value }) => (
              <option key={id} value={id}>
                {value}
              </option>
            ))}
          </Select>
          <FrontError>{errors.ocupacion && errors.ocupacion?.msg}</FrontError>
        </FullWidth>
        <OcupationContainer>
          <h1>¿Desempeñas algún cargo en el estado?</h1>
          <h2>(P.E.P o Persona expuesta publicamente)</h2>
          <OcupationBtnWrap>
            <Button
              type="button"
              background={state.personaExpuesta === 0 ? '#323232' : null}
              onClick={() => hPEP(0)}
            >
              NO
            </Button>
            <Button
              type="button"
              background={state.personaExpuesta === 1 ? '#323232' : null}
              onClick={() => hPEP(1)}
            >
              SI
            </Button>
          </OcupationBtnWrap>
          <FrontError>
            {errors.personaExpuesta && errors.personaExpuesta?.msg}
          </FrontError>
        </OcupationContainer>
        <Button type="submit">Siguiente</Button>
      </Form>
    </Container>
  )
}

NewPersonOcupation.propTypes = {
  history: PropTypes.object.isRequired
}

export default NewPersonOcupation

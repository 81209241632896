import styled from 'styled-components'

export const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  padding-inline: 35px;
  padding-top: ${({ center }) => (center ? '100px' : '30px')};

  @media screen and (min-width: 1100px) {
    margin: 0 auto;
    max-width: 1100px;
  }
`

export const AppForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ grid }) => `repeat(${grid}, 1fr)`};
  gap: 20px;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-bottom: 0px;
  }
`

export const Center = styled.div`
  height: ${({ height }) => `${height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`

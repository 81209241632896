import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Wrapper, AppContainer } from '../../components/App/App.styles'
import {
  Input,
  Select,
  Headline,
  BtnsWrap,
  InputItem,
  FullWidth,
  Placeholer,
  FrontError,
  FullWidthMobile
} from '../../components/Auth/Auth.styles'
import LoadingScreen from '../../components/Loading'
import { Button } from '../../components/Buttons/Buttons.styles'

import { banks } from '../fakeData'
import useForm from '../../hooks/useForm'
import AppContext from '../../context/App/AppContext'
import { bankAccValid } from '../../helpers/formValidation'
import { editBAStore, newBAStore } from '../../apollo/actions'
import { EDIT_BANK_ACCOUNT, NEW_BANK_ACCOUNT } from '../../apollo/queries'

const initialState = {
  tipoCuenta: '',
  banco: '',
  alias: '',
  tipoMoneda: '',
  ownership: false,
  nroCuenta: ''
}

const AddBankAccount = ({ history, location }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const { selectedProfile, setEditBankAcc } = useContext(AppContext)
  const [formValues, hOnChange, setState] = useForm(() => {
    if (location.state) return location.state
    return initialState
  })

  const {
    tipoCuenta,
    banco,
    alias,
    tipoMoneda,
    ownership,
    nroCuenta
  } = formValues

  const [editBankAcc, { loading: loadingEditBA }] = useMutation(
    EDIT_BANK_ACCOUNT
  )
  const [newBankAccount, { loading: loadingNewBA }] = useMutation(
    NEW_BANK_ACCOUNT
  )

  useEffect(() => {
    if (isDirty) {
      const validation = bankAccValid(formValues)
      setErrors(validation)
    }
  }, [formValues])

  const hOnSubmit = async () => {
    const validation = bankAccValid(formValues)
    setIsDirty(true)

    if (!validation.ok) return setErrors(validation)

    const payload = {
      alias,
      nroCuenta,
      banco: String(banco),
      tipoMoneda: parseInt(tipoMoneda),
      tipoCuenta: parseInt(tipoCuenta),
      perfilId: selectedProfile.perfilId
    }

    if (location.state) {
      setEditBankAcc(formValues)
      const { cuentaPerfilId } = formValues
      const { perfilId } = selectedProfile
      history.push('/app/bankAccounts')
      await editBankAcc({
        variables: { input: { ...payload, cuentaPerfilId } },
        update: (store) => {
          editBAStore(store, { ...payload, cuentaPerfilId }, perfilId)
        }
      })
      return
    }

    try {
      await newBankAccount({
        variables: { input: payload },
        update: (store, { data }) => {
          newBAStore(store, data, selectedProfile.perfilId)
        }
      })
      history.push('/app/bankAccounts')
    } catch (error) {
      console.log(error)
    }
  }

  const hOnChangeAcc = ({ target }) => {
    const value = target.value
    const account = banks.filter(({ id }) => id === parseInt(value))
    const { id, ...rest } = account[0]
    setState({
      ...formValues,
      ...rest,
      banco: id
    })
  }

  const hOnCheck = ({ target }) => {
    setState({
      ...formValues,
      [target.name]: target.checked
    })
  }

  if (loadingEditBA || loadingNewBA) return <LoadingScreen />

  return (
    <AppContainer>
      <Headline expand>
        {location.state ? 'Editar Cuenta Bancaria' : 'Agregar Cuenta Bancaria'}
      </Headline>
      <FullWidthMobile>
        <Wrapper grid={3}>
          <FullWidth>
            <Select name="tipoCuenta" value={tipoCuenta} onChange={hOnChange}>
              <option value="">Seleccione tipo de cuenta</option>
              <option value="0">Ahorro</option>
              <option value="1">Corriente</option>
            </Select>
            <FrontError>{errors.type && errors.type?.msg}</FrontError>
          </FullWidth>
          <FullWidth>
            <Select name="tipoMoneda" value={tipoMoneda} onChange={hOnChange}>
              <option value="">Seleccione moneda</option>
              <option value="0">Soles (S/)</option>
              <option value="1">Dolares ($)</option>
            </Select>
            <FrontError>
              {errors.tipoMoneda && errors.tipoMoneda?.msg}
            </FrontError>
          </FullWidth>
          <InputItem>
            <Input
              type="text"
              name="alias"
              value={alias}
              autoComplete="off"
              onChange={hOnChange}
              valid={alias.trim() !== ''}
            />
            <Placeholer>Alias</Placeholer>
            <FrontError>{errors.alias && errors.alias?.msg}</FrontError>
          </InputItem>
        </Wrapper>
        <Wrapper grid={2}>
          <FullWidth>
            <Select name="banco" value={banco} onChange={hOnChangeAcc}>
              <option value="">Seleccione su Banco</option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.bank}
                </option>
              ))}
            </Select>
            <FrontError>{errors.bank && errors.bank?.msg}</FrontError>
          </FullWidth>
          <InputItem>
            <Input
              type="number"
              autoComplete="off"
              name="nroCuenta"
              onChange={hOnChange}
              value={nroCuenta}
              valid={nroCuenta !== ''}
            />
            <Placeholer>N° de Cuenta</Placeholer>
            <FrontError>{errors.nroCuenta && errors.nroCuenta?.msg}</FrontError>
          </InputItem>
        </Wrapper>
        <div>
          <input
            id="ownership"
            type="checkbox"
            name="ownership"
            checked={ownership}
            onChange={hOnCheck}
          />
          <label htmlFor="ownership"> Declaro que esta cuenta es mia.</label>
          <FrontError>{errors.ownership && errors.ownership?.msg}</FrontError>
        </div>
      </FullWidthMobile>
      <BtnsWrap>
        <Button onClick={() => history.push('/app/bankAccounts')}>
          Cancelar
        </Button>
        <Button onClick={hOnSubmit} disabled={loadingEditBA || loadingNewBA}>
          {location.state ? 'Editar' : 'Agregar'}
        </Button>
      </BtnsWrap>
    </AppContainer>
  )
}

AddBankAccount.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default AddBankAccount

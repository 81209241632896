import React from 'react'
import { FooterSection, SectionTitle, SectionItem } from './Footer.styles'

const FooterLinks = () => {
  return (
    <>
      <FooterSection>
        <SectionTitle>Empresa</SectionTitle>
        <SectionItem>Nosotros</SectionItem>
        <SectionItem>Empresas</SectionItem>
        <SectionItem>Blog</SectionItem>
        <SectionItem>Trabaja con nosotros</SectionItem>
      </FooterSection>
      <FooterSection>
        <SectionTitle>Más Información</SectionTitle>
        <SectionItem>Promociones</SectionItem>
        <SectionItem>Configurar Alertas</SectionItem>
        <SectionItem>Tipo de Cambio Hoy</SectionItem>
        <SectionItem>¿Qué es Kambista?</SectionItem>
      </FooterSection>
      <FooterSection>
        <SectionTitle>Ayuda</SectionTitle>
        <SectionItem>Atención al Cliente</SectionItem>
        <SectionItem>Preguntas Frecuentes</SectionItem>
      </FooterSection>
      <FooterSection>
        <SectionTitle>Legal</SectionTitle>
        <SectionItem>Términos y Condiciones</SectionItem>
        <SectionItem>Políticas de Privacidad</SectionItem>
        <SectionItem>Libro de Reclamaciones</SectionItem>
      </FooterSection>
    </>
  )
}

export default FooterLinks

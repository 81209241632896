import styled from 'styled-components'

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: grid;
  place-items: center;
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

  transition: 0.3s ease-in-out;
`

export const ModalWrapper = styled.div`
  width: 300px;
  background: #fff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid #ebebeb;
`

export const ModalTitle = styled.p`
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
`

export const ModalBtnWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 25px;
`

export const ModalContent = styled.p`
  font-size: 18px;
  margin-inline: 5px;
`

import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import PropTypes from 'prop-types'
import {
  DetailsContainer,
  DetailsRow,
  DetailsTable,
  DetailsTitle,
  TextBold,
  TextMuted
} from '../../components/Details/Detail.styles'

import { GET_BANK_ACCOUNTS_ADMIN } from '../../apollo/queries'
import LoadingScreen from '../../components/Loading'

const AccToTranfer = ({ adminBank }) => {
  const { loading, data } = useQuery(GET_BANK_ACCOUNTS_ADMIN)

  const bankAccAdmin = data ? data.GetAllCuentaAdministrador : []

  const [bankAcc] = useMemo(() => {
    return bankAccAdmin.filter(({ cuentaAdmId }) => cuentaAdmId === adminBank)
  }, [adminBank])

  const isPEN = bankAcc?.tipoMoneda === 0
  const isAhorro = bankAcc?.tipoCuenta === 0

  if (loading) return <LoadingScreen />

  return (
    <DetailsContainer>
      <DetailsTable>
        <DetailsRow title="true">
          <DetailsTitle>Datos de la cuenta a transferir</DetailsTitle>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Banco</TextMuted>
          <TextBold>{bankAcc.nombre}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Tipo de Cuenta</TextMuted>
          <TextBold>{isAhorro ? 'Ahorro' : 'Corriente'}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Moneda de la Cuenta</TextMuted>
          <TextBold>{isPEN ? 'Soles' : 'Dolares'}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Numero de cuenta</TextMuted>
          <TextBold>{bankAcc.nroCuenta}</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>Titular</TextMuted>
          <TextBold>Ktalcambio SAC</TextBold>
        </DetailsRow>
        <DetailsRow>
          <TextMuted>RUC</TextMuted>
          <TextBold>20601708141</TextBold>
        </DetailsRow>
      </DetailsTable>
    </DetailsContainer>
  )
}

AccToTranfer.propTypes = {
  adminBank: PropTypes.string.isRequired
}

export default AccToTranfer

import styled from 'styled-components'

export const LoadingContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const LoadingLogo = styled.div`
  width: 300px;
`

export const LoadingRing = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #01c794;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #01c794 transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

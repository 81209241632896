import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import AppReducer from './AppReducer'
import AppContext from './AppContext'
import {
  SET_USER,
  SET_PROFILE,
  SET_PROFILES,
  PURGE_PROFILE,
  SET_NEW_BANKACC,
  SET_NEW_PROFILE,
  SET_INFO_PROFILE,
  SET_EDIT_BANKACC,
  SET_DELETE_BANKACC,
  SET_NEW_TRANSACTION,
  SET_PHOTOS_PROFILE,
  SET_ADMIN_BANKACC,
  SET_TRANSACTIONS
} from './AppTypes'
import { initialState } from './initialState'

const AppState = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  // Accion para asignar usuario
  const setUser = (payload) => {
    dispatch({ type: SET_USER, payload })
  }

  // Accion para seleccionar perfil
  const setProfiles = (payload) => {
    dispatch({ type: SET_PROFILES, payload })
  }

  // Accion para seleccionar perfil
  const setProfile = (payload) => {
    localStorage.setItem('perfilId', payload.perfilId)
    dispatch({ type: SET_PROFILE, payload })
  }

  // Accion para seleccionar perfil
  const purgeProfile = () => {
    dispatch({ type: PURGE_PROFILE })
  }

  // Accion para crear nuevo perfil
  const setNewProfile = (payload) => {
    dispatch({ type: SET_NEW_PROFILE, payload })
  }

  // Accion para crear nueva transaccion
  const setTransactions = (payload) => {
    dispatch({ type: SET_TRANSACTIONS, payload })
  }

  // Accion para crear nueva transaccion
  const setNewTransaction = (payload) => {
    dispatch({ type: SET_NEW_TRANSACTION, payload })
  }

  // Accion para crear nueva cuenta bancaria
  const setNewBankAcc = (payload) => {
    dispatch({ type: SET_NEW_BANKACC, payload })
  }

  // Accion para editar cuenta bancaria
  const setEditBankAcc = (payload) => {
    dispatch({ type: SET_EDIT_BANKACC, payload })
  }

  // Accion para setear las cuentas de administrador en el state
  const setAdminBankAcc = (payload) => {
    dispatch({ type: SET_ADMIN_BANKACC, payload })
  }

  // Accion para elminar cuenta bancaria
  const setDeleteBankAcc = (payload) => {
    dispatch({ type: SET_DELETE_BANKACC, payload })
  }

  // Accion para actualizar datos
  const setInfoProfile = (payload) => {
    dispatch({ type: SET_INFO_PROFILE, payload })
  }

  // Accion para verificar cuenta
  const setPhotosProfile = (payload) => {
    dispatch({ type: SET_PHOTOS_PROFILE, payload })
  }

  const exports = {
    // States
    profiles: state.profiles,
    info: state.selectedProfile.info,
    selectedProfile: state.selectedProfile,
    adminBankAccounts: state.adminBankAccounts,
    // Funciones
    setUser,
    setProfile,
    setProfiles,
    purgeProfile,
    setNewProfile,
    setInfoProfile,
    setNewBankAcc,
    setEditBankAcc,
    setDeleteBankAcc,
    setNewTransaction,
    setTransactions,
    setAdminBankAcc,
    setPhotosProfile
  }

  return (
    <AppContext.Provider value={exports}>{props.children}</AppContext.Provider>
  )
}

AppState.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppState

import styled from 'styled-components'

export const FooterContainer = styled.div`
  background: #222;
  padding: 35px;
`

export const FooterHeader = styled.div`
  display: flex;
  justify-content: center;
`

export const FooterLogo = styled.img`
  width: 150px;
`

export const FooterWrapper = styled.div`
  margin: 0 auto;
  max-width: 930px;
  display: flex;
  flex-wrap: wrap;
  margin-block: 35px;
  justify-content: space-between;
`

export const FooterSection = styled.div``

export const SectionTitle = styled.p`
  color: #fff;
  margin: 15px 0;
  font-size: 20px;
`

export const SectionItem = styled.p`
  margin: 0;
  color: #e2e2e2;
  margin-bottom: 5px;
  font-size: 15px;
`
export const FooterSocial = styled.div`
  margin-block: 40px;
  width: 100%;

  @media screen and (min-width: 750px) {
    margin: 0 auto;
    margin-block: 40px;
    width: 300px;
  }
`

export const SocialTitle = styled.p`
  text-align: center;
  color: #fff;
  margin: 15px 0;
  font-size: 20px;
`

export const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`
export const FooterCompany = styled.div`
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const FooterText = styled.p`
  margin: 0;
  color: #fff;
  font-size: 18px;
  text-align: center;
`

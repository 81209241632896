import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaChevronCircleDown } from 'react-icons/fa'

import {
  ChevronIcon,
  MenuBtn,
  MenuItem,
  MenuTitleWrap,
  SubMenu,
  SubMenuItem
} from './Accordion.styles'
import { banks } from '../../pages/fakeData'
import { DetailsSup } from '../Details/Detail.styles'
import { exchangeRateFormat, numberFormat } from '../../helpers/numberFormat'

const TransactionItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    estado,
    cuponId,
    montoCupon,
    transaccionId,
    tipoTransaccion,
    valorMonedaActual,
    cantidadMonedaCambiar,
    cuentaAdministrador,
    cuentaPerfil,
    totalTransaccion,
    created_at: createdAt
  } = item

  const isPEN = tipoTransaccion === 2
  const bankInfo = banks[parseInt(cuentaPerfil.banco) + 1]
  const tipoCambio = cuponId
    ? isPEN
      ? valorMonedaActual - montoCupon
      : valorMonedaActual + montoCupon
    : valorMonedaActual
  const dateFormat = new Date(createdAt).toLocaleString('es-ES', {
    hour12: true
  })

  /* eslint-disable */
  const status =
    estado === 0
      ? 'En proceso'
      : estado === 1
      ? 'Pagando'
      : estado === 2
      ? 'Exitosa'
      : 'Anulada'
  /* eslint-enable */

  return (
    <MenuItem>
      <MenuBtn onClick={() => setIsOpen(!isOpen)}>
        <MenuTitleWrap>
          <p>Transaccion N°: </p>
          <strong>{transaccionId}</strong>
        </MenuTitleWrap>
        <ChevronIcon isOpen={isOpen}>
          <FaChevronCircleDown />
        </ChevronIcon>
      </MenuBtn>
      <SubMenu isOpen={isOpen}>
        <SubMenuItem>
          <p>Estatus</p>
          <DetailsSup estado={estado}>
            <p>{status}</p>
          </DetailsSup>
        </SubMenuItem>
        <SubMenuItem>
          <p>Orden Creada</p>
          <strong>{dateFormat}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Cuenta de destino</p>
          <strong>{cuentaPerfil.nroCuenta}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Banco de Origen</p>
          <strong>{cuentaAdministrador.nombre}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Banco de Destino</p>
          <strong>{bankInfo.bankAbrr}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Monto a pagar</p>
          <strong>{numberFormat(cantidadMonedaCambiar, isPEN)}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Monto a recibir</p>
          <strong>{numberFormat(totalTransaccion, !isPEN)}</strong>
        </SubMenuItem>
        <SubMenuItem hasCupon={cuponId}>
          <p>Tipo de cambio</p>
          <strong>{exchangeRateFormat(valorMonedaActual)}</strong>
        </SubMenuItem>
        {cuponId && (
          <SubMenuItem>
            <p>Cupon aplicado</p>
            <strong>{exchangeRateFormat(tipoCambio)}</strong>
          </SubMenuItem>
        )}
      </SubMenu>
    </MenuItem>
  )
}

TransactionItem.propTypes = {
  item: PropTypes.object.isRequired
}

export default TransactionItem

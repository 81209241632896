import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  FrontError,
  FullWidth,
  Select,
  SelectLabel
} from '../../components/Auth/Auth.styles'
import { useQuery } from '@apollo/client'
import {
  GET_BANK_ACCOUNTS,
  GET_TRANSACTIONS_BYPROFILE
} from '../../apollo/queries'
import AppContext from '../../context/App/AppContext'

const SelectBankAccUser = ({ formValues, hOnChange, errors }) => {
  const { selectedProfile } = useContext(AppContext)

  useQuery(GET_TRANSACTIONS_BYPROFILE, {
    variables: {
      page: 1,
      number_paginate: 50,
      perfilId: selectedProfile.perfilId
    }
  })

  const { loading, data } = useQuery(GET_BANK_ACCOUNTS, {
    variables: { perfilId: selectedProfile.perfilId }
  })

  const baUser = data ? data.GetAllCuentaPerfil : []

  const accountsFilter = useMemo(() => {
    return baUser.filter(({ tipoMoneda }) => {
      return tipoMoneda + 1 === formValues.tipoTransaccion
    })
  }, [formValues.tipoTransaccion, data])

  return (
    <FullWidth>
      <SelectLabel>¿En qué cuenta deseas recibir tu dinero?</SelectLabel>
      <Select
        withLabel
        name="cuentaPerfil"
        disabled={loading}
        onChange={(e) => hOnChange(e, baUser)}
        value={formValues.cuentaPerfil.cuentaPerfilId}
      >
        <option value="">Seleccione la Cuenta</option>
        {accountsFilter.length !== 0 &&
          accountsFilter.map(({ cuentaPerfilId, tipoMoneda, nroCuenta }) => (
            <option key={cuentaPerfilId} value={cuentaPerfilId}>
              {tipoMoneda === 0 ? 'SOLES - ' : 'DOLARES - '}
              {nroCuenta}
            </option>
          ))}
      </Select>
      <FrontError>{errors.cuentaPerfil && errors.cuentaPerfil?.msg}</FrontError>
    </FullWidth>
  )
}

SelectBankAccUser.propTypes = {
  errors: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired
}

export default SelectBankAccUser

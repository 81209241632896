import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { BtnsWrap, Headline } from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons/Buttons.styles'
import { AppContainer, AppForm } from '../../components/App/App.styles'

import LoadingScreen from '../../components/Loading'
import InputPass from '../../components/Inputs/InputPass'

import useForm from '../../hooks/useForm'
import { CHANGE_PASS } from '../../apollo/queries'
import { changePassValid } from '../../helpers/formValidation'
import security from '../../assets/change-pass.svg'

const ChangePassContainer = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  img {
    max-width: 500px;

    @media screen and (max-width: 960px) {
      display: none;
    }
  }
`

const FormWrapper = styled.div`
  max-width: 400px;
`

const ChangePassword = ({ history }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange] = useForm({
    password_antiguo: '',
    password_nuevo: ''
  })

  const [changePass] = useMutation(CHANGE_PASS)

  useEffect(() => {
    if (isDirty) {
      const validation = changePassValid(state)
      setErrors(validation)
    }
  }, [state])

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    setIsDirty(true)

    const validation = changePassValid(state)
    if (!validation.ok) return setErrors(validation)

    try {
      await changePass({
        variables: { input: state }
      })
      history.push('/app/success', { msg: '¡Cambio de contraseña exitoso!' })
    } catch (error) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err) => {
          console.info(`ChangePassword: ${err.debugMessage}`)
          if (err.debugMessage === 'CONTRASEÑA_INCORRECTA') {
            setErrors({
              ok: false,
              password_antiguo: { msg: 'Contraseña invalida.' }
            })
          }
        })
      }
    }
  }

  if (!errors) return <LoadingScreen />

  return (
    <AppContainer>
      <ChangePassContainer>
        <img src={security} alt="illustracion de seguridad" />
        <AppForm onSubmit={handleOnSubmit}>
          <FormWrapper>
            <Headline expand>Cambiar Contraseña</Headline>

            <InputPass
              name="password_antiguo"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Contraseña anterior"
            />
            <InputPass
              name="password_nuevo"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Contraseña nueva"
            />

            <BtnsWrap>
              <Button onClick={() => history.push('/app/transaction/new')}>
                Cancelar
              </Button>
              <Button type="submit">Actualizar</Button>
            </BtnsWrap>
          </FormWrapper>
        </AppForm>
      </ChangePassContainer>
    </AppContainer>
  )
}

ChangePassword.propTypes = {
  history: PropTypes.object
}

export default ChangePassword

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Select,
  Headline,
  FullWidth,
  Container,
  FrontError,
  OcupationBtnWrap,
  OcupationContainer
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons'

import { ocupations } from '../fakeData'
import useForm from '../../hooks/useForm'
import AuthContext from '../../context/Auth/AuthContext'
import { ocupationValid } from '../../helpers/formValidation'

const RegisterOcupation = ({ history }) => {
  const { formValues, setRegAction } = useContext(AuthContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange, setState] = useForm({
    ocupacion: '',
    personaExpuesta: null
  })

  useEffect(() => {
    if (isDirty) {
      const validation = ocupationValid(state)
      setErrors(validation)
    }

    if (Object.keys(formValues).length === 0) {
      history.push('/auth')
    }
  }, [state])

  const HPEP = (number) => {
    setState({ ...state, personaExpuesta: number })
  }

  const hOnSubmit = (e) => {
    e.preventDefault()

    const validation = ocupationValid(state)
    setErrors(validation)

    setIsDirty(true)

    if (validation.ok) {
      setRegAction(state)
      history.push('/auth/register/verify')
    }
  }

  return (
    <Container size="large" center>
      <Headline expand>Ocupacion</Headline>
      <Form onSubmit={hOnSubmit}>
        <FullWidth>
          <Select name="ocupacion" value={state.ocupacion} onChange={hOnChange}>
            <option value="">Seleccione una ocupación</option>
            {ocupations.map(({ id, value }) => (
              <option key={id} value={id}>
                {value}
              </option>
            ))}
          </Select>
          <FrontError>{errors.ocupacion && errors.ocupacion?.msg}</FrontError>
        </FullWidth>
        <OcupationContainer>
          <h1>¿Desempeñas algún cargo en el estado?</h1>
          <h2>(P.E.P o Persona expuesta publicamente)</h2>
          <OcupationBtnWrap>
            <Button
              type="button"
              background={state.personaExpuesta === 0 ? '#323232' : null}
              onClick={() => HPEP(0)}
            >
              NO
            </Button>
            <Button
              type="button"
              background={state.personaExpuesta === 1 ? '#323232' : null}
              onClick={() => HPEP(1)}
            >
              SI
            </Button>
          </OcupationBtnWrap>
          <FrontError>
            {errors.personaExpuesta && errors.personaExpuesta?.msg}
          </FrontError>
        </OcupationContainer>
        <Button type="submit">Siguiente</Button>
      </Form>
    </Container>
  )
}

RegisterOcupation.propTypes = {
  history: PropTypes.object.isRequired
}

export default RegisterOcupation

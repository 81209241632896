import React, { useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Redirect, Switch, useHistory } from 'react-router-dom'

import AppRouter from './AppRouter'
import AuthRouter from './AuthRouter'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import PublicRoutes from './PublicRoutes'
import PrivateRoute from './PrivateRoutes'
import Sidebar from '../components/Sidebar'
import AppContext from '../context/App/AppContext'
import AuthContext from '../context/Auth/AuthContext'
// import LandingPage from '../pages/Landing/LandingPage'
import NewPState from '../context/NewProfile/NewPState'

import { GET_PERFILES_PERSONA } from '../apollo/queries'
import LoadingScreen from '../components/Loading'
import { mapProfiles, mapProfilesBusiness } from '../helpers/MapProfiles'

const RootRouter = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { isAuth, loginAction } = useContext(AuthContext)
  const { setProfiles } = useContext(AppContext)

  const [getProfilesByToken] = useLazyQuery(GET_PERFILES_PERSONA, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ GetUserPerfilesEmpresa, GetUserPerfilesPersona }) => {
      console.log({ GetUserPerfilesEmpresa, GetUserPerfilesPersona })
      if (GetUserPerfilesEmpresa && GetUserPerfilesPersona) {
        // Obtener todos los perfiles segun el token guardado en cache
        const profilesP = mapProfiles(GetUserPerfilesPersona)
        const profilesB = mapProfilesBusiness(GetUserPerfilesEmpresa)
        const profiles = [...profilesP, ...profilesB]
        // Setear los perfiles ya mapeados en el AppState
        setProfiles(profiles)
        // Filtro el ultimo perfil utilizado por el usuario
        const [selectedProfile] = profiles
        // if (selectedProfile.info.estado === 1 || !selectedProfile.info.estado) {
        //   setIsLoading(false)
        //   history.push('/auth/register/document', selectedProfile)
        //   return
        // }

        // Selecciono el perfil
        // setProfile(selectedProfile)
        // // Logueo al usuario
        setIsLoading(false)
        if (!selectedProfile) return history.push('/auth')
        loginAction(selectedProfile.info.User)

        history.push('/app')
      }
    }
  })

  useEffect(() => {
    setIsLoading(true)
    const token = localStorage.getItem('token')
    if (token) return getProfilesByToken()
    setIsLoading(false)
  }, [])

  if (isLoading) return <LoadingScreen />

  return (
    <NewPState>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Navbar setIsOpen={setIsOpen} />
      <div>
        <Switch>
          {/* <Route exact path="/" component={LandingPage} /> */}
          <PrivateRoute isAuth={isAuth} path="/app" component={AppRouter} />
          <PublicRoutes isAuth={isAuth} path="/auth" component={AuthRouter} />

          <Redirect to="/auth" />
        </Switch>
      </div>
      <Footer />
    </NewPState>
  )
}

export default RootRouter

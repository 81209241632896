import React from 'react'
import PropTypes from 'prop-types'

import {
  ModalTitle,
  ModalWrapper,
  ModalBtnWrap,
  ModalContainer,
  ModalContent
} from './Modal.styles'
import { Button } from '../Buttons/Buttons.styles'

const Modal = ({
  isOpen = false,
  title = 'Confirmar',
  content = '',
  onClickAccept,
  onClickCancel
}) => {
  return (
    <ModalContainer isOpen={isOpen}>
      <ModalWrapper>
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>{content}</ModalContent>
        <ModalBtnWrap>
          <Button onClick={onClickCancel} background="#EC8A6F">
            Cancelar
          </Button>
          <Button onClick={onClickAccept}>Aceptar</Button>
        </ModalBtnWrap>
      </ModalWrapper>
    </ModalContainer>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  onClickAccept: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default Modal

import { useState } from 'react'

const useForm = (initialState) => {
  const [state, setState] = useState(initialState)

  const hOnChange = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value
    })
  }

  // const hInputFile = ({ target }) => {
  //   if (target.files) {
  //     const file = target.files[0]
  //     const reader = new FileReader()
  //     reader.readAsDataURL(file)
  //     reader.onload = (e) => {
  //       setState({
  //         ...state,
  //         [target.name]: e.target.result
  //       })
  //     }
  //   }
  // }

  const reset = () => {
    setState(initialState)
  }

  return [state, hOnChange, setState, reset]
}

export default useForm

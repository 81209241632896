import React from 'react'
import PropTypes from 'prop-types'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const PaginationBtn = styled.button`
  border: 1px solid transparent;
  outline: none;
  background: transparent;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 10px;

  transition: border 0.3s ease-in;

  &:disabled svg {
    color: #9d9d9d;
  }

  &:disabled:hover {
    border: 1px solid transparent;
  }

  &:hover {
    border: 1px solid #01c794;
  }

  svg {
    font-size: 20px;
    color: #323232;
    border: 1px solid transparent;
    cursor: pointer;
  }
`

const Pagination = ({ currentPage = 1, numbersPages = 2, setPage }) => {
  return (
    <Container>
      <PaginationBtn
        disabled={currentPage === 1}
        onClick={() => setPage((page) => page - 1)}
      >
        <FiChevronLeft />
      </PaginationBtn>
      <p>
        {currentPage} de {numbersPages()}
      </p>
      <PaginationBtn
        disabled={currentPage === numbersPages()}
        onClick={() => setPage((page) => page + 1)}
      >
        <FiChevronRight />
      </PaginationBtn>
    </Container>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numbersPages: PropTypes.func,
  setPage: PropTypes.func
}

export default Pagination

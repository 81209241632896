import styled from 'styled-components'
import { FaExchangeAlt } from 'react-icons/fa'

export const ExchangeContainer = styled.div`
  position: relative;
  max-width: 350px;
  min-height: 300px;
  padding-bottom: 20px;
  background: white;
  border-radius: 2rem;
  border: 1px solid #ebebeb;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.1);
`
export const ExchangeTitle = styled.h2`
  text-align: center;
  margin: 0;
  margin-top: 20px;
`

export const CuponTitle = styled.p`
  margin: 0;
  font-size: 20px;
  text-align: center;
`

export const ExchangeWrapper = styled.div`
  padding: 0 25px;
`

export const ExchangeCol = styled.div`
  display: flex;
  transition: all 0.3s;
  /* flex-direction: column; */
  flex-direction: ${({ isSolFirst }) =>
    isSolFirst ? 'column' : 'column-reverse'};
`

export const ExchangeRow = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
`

export const ExchangeLabel = styled.label`
  font-size: 1.25rem;
  font-weight: 500;
  text-align: ${({ center }) => (center ? 'center' : '')};
  width: ${({ center }) => (center ? '100%' : '')};
`

export const ExchangeFlag = styled.img`
  width: 38px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #9d9d9d;
`

export const ExchangeAmount = styled.input`
  width: 55%;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  margin: 0 15px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 500;
  transition: 200ms ease-in-out;
  /* border-bottom: 1px solid
    ${({ disabled }) => (disabled ? 'transparent' : '#e2e2e2')}; */

  &:focus,
  &:active {
    border-bottom: 1px solid #807f7f;
  }

  &:disabled {
    background: transparent;
  }
`

export const ExchangeHr = styled.div`
  position: relative;
  width: 80%;
  height: 1px;
  margin: 0 auto;
  background: #ebebeb;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    width: 40px;
    height: 40px;
    background: white;
    border: 1px solid #ebebeb;
    border-radius: 50%;
  }
`

export const ExchangeAction = styled(FaExchangeAlt)`
  position: absolute;
  right: 9px;
  bottom: -11px;
  font-size: 22px;
  color: #323232;
  cursor: pointer;
  transform: rotate(90deg);
  z-index: 2;

  transition: color 0.3s ease-in;

  &:hover {
    color: #111;
  }
`
export const ExchangeBtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

export const ExchangeBtn = styled.button`
  border: 0;
  outline: 0;
  border-radius: 8px;
  margin: 0 auto;
  padding: 0.8rem 3rem;
  color: #fff;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  background: #222;
`

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
`

export const InfoCol = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: ${({ borderRight }) =>
    borderRight ? '1px solid #9d9d9d' : ''};
  margin-right: ${({ borderRight }) => (borderRight ? '10px' : '')};
  padding-right: ${({ borderRight }) => (borderRight ? '10px' : '')};
`
export const InfoAmount = styled.div`
  margin: 0;
  color: ${({ isActive }) => (isActive ? '#222' : '#9d9d9d')};
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 20px;
  display: flex;

  div {
    p {
      margin: 0;
      opacity: ${({ isActive }) => (isActive ? '1' : '0.3')};

      ${({ hasCupon }) => {
        if (hasCupon) {
          return ` &:nth-child(1) {
                    text-decoration: line-through;
                    color: #f10101;
                    font-size: 18px;
                    }
                    &:nth-child(2) {
                      color: #01cf01;
                    }`
        }
      }}
    }
  }
`

export const InfoTitle = styled.p`
  margin: 0;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
  font-size: 1.25rem;
  font-weight: ${({ isActive }) => (isActive ? '600' : '100')};
  margin-bottom: 10px;
`

import React from 'react'
import PropTypes from 'prop-types'
import { useGoogleLogin } from 'react-google-login'

import googleIcon from '../../assets/google.png'
import { AuthSocialBtn, AuthSocialIcon } from './Buttons.styles'

const GoogleButton = ({ handleLogin, handleRegister }) => {
  // const history = useHistory()
  const { signIn } = useGoogleLogin({
    onFailure,
    onSuccess,
    isSignedIn: false,
    clientId: process.env.REACT_APP_GOOGLE_ID
  })

  async function onSuccess({ profileObj }) {
    const input = {
      tipoInicio: 3,
      email: profileObj.email,
      password: profileObj.googleId
    }
    const { isRegister } = await handleLogin(input)
    console.log(isRegister)
    if (!isRegister) {
      await handleRegister({
        ...input,
        name: profileObj.givenName
      })
    }
  }

  function onFailure(response) {
    console.log(response)
  }

  return (
    <AuthSocialBtn onClick={signIn} color="#222" background="#F3F3FC">
      <AuthSocialIcon src={googleIcon} alt="Logo de Google" />
      <p>Accede con Google</p>
    </AuthSocialBtn>
  )
}

GoogleButton.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  handleRegister: PropTypes.func.isRequired
}

export default GoogleButton

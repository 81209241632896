import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import AuthReducer from './AuthReducer'
import AuthContext from './AuthContext'
import {
  LOGIN_ACTION,
  LOGOUT_ACTION,
  EMAIL_REGISTER,
  SOCIAL_REGISTER,
  PROFILE_REGISTER,
  SET_REGISTER,
  PURGE_REGISTER,
  SET_USER_ACTION
} from './authTypes'

const initialState = {
  user: null,
  isAuth: false,
  isLoading: false,
  register: {
    mode: 'email',
    accountType: '',
    formValues: {}
  }
}

const AuthState = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  // Acciones para el proceso de login
  const loginAction = (payload) => {
    localStorage.setItem('token', payload.apiToken)
    dispatch({ type: LOGIN_ACTION, payload })
  }
  // Acciones para setear usuario en el proceso de registro
  const setUserAction = (payload) => {
    dispatch({ type: SET_USER_ACTION, payload })
  }
  // Acciones para el proceso de logout
  const logoutAction = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('perfilId')
    dispatch({ type: LOGOUT_ACTION })
  }

  //* Accion para cargar informacion al state */
  const setRegAction = (payload) => {
    dispatch({ type: SET_REGISTER, payload })
  }
  //* Accion para registrarse con email */
  const emailRegAction = () => {
    dispatch({ type: EMAIL_REGISTER })
  }
  //* Accion para registrarse con Google o FB */
  const socialRegAction = (payload) => {
    dispatch({ type: SOCIAL_REGISTER, payload: payload })
  }
  //* Accion para definir el perfil de registro Personal o empresarial */
  const profileRegAction = (payload) => {
    dispatch({ type: PROFILE_REGISTER, payload: payload })
  }
  //* Purge formulario de registro */
  const purgeRegAction = () => {
    dispatch({ type: PURGE_REGISTER, payload: initialState.register })
  }

  const exports = {
    // Estado
    user: state.user,
    isAuth: state.isAuth,
    register: state.register,
    formValues: state.register.formValues,
    isLoading: state.isLoading,
    // Funciones
    loginAction,
    logoutAction,
    setRegAction,
    emailRegAction,
    socialRegAction,
    profileRegAction,
    setUserAction,
    purgeRegAction
  }

  return (
    <AuthContext.Provider value={exports}>
      {props.children}
    </AuthContext.Provider>
  )
}

AuthState.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthState

export const banks = [
  { id: 1, bankAbrr: 'BCP', bank: 'Banco de Crédito del Perú' },
  { id: 2, bankAbrr: 'Interbank', bank: 'Banco Internacional del Perú' },
  { id: 3, bankAbrr: 'BBVA', bank: 'BBVA Continental' },
  { id: 4, bankAbrr: 'BanBif', bank: 'BanBif' },
  { id: 5, bankAbrr: 'Scotiabank', bank: 'Scotiabank' },
  { id: 6, bankAbrr: 'Falabella', bank: 'Falabella' },
  { id: 7, bankAbrr: 'Pichincha', bank: 'Pichincha' },
  { id: 8, bankAbrr: 'Bancomercio', bank: 'Banco de Comercio' },
  { id: 9, bankAbrr: 'Citybank', bank: 'Citibank Perú' },
  { id: 10, bankAbrr: 'Mibanco', bank: 'Mi Banco' },
  { id: 11, bankAbrr: 'GNB', bank: 'Banco GNB' },
  { id: 12, bankAbrr: 'Ripley', bank: 'Banco Ripley' },
  { id: 13, bankAbrr: 'Nacion', bank: 'Banco de la Nación' },
  { id: 14, bankAbrr: 'Otro', bank: 'Otro' }
]

export const userBankAcounts = [
  {
    id: 0,
    tag: null,
    type: 'account',
    content: 'Seleccione Cuenta de Destino'
  },
  { id: 1, type: 'account', tag: '19191354683055', content: '19191354683055' }
]

export const originFunds = [
  { id: 0, tag: null, content: 'Seleccione el Origen de los Fondos' },
  { id: 1, tag: 'Ahorros', content: 'Ahorros' },
  {
    id: 2,
    tag: 'Alquiler de bienes muebles',
    content: 'Alquiler de bienes muebles'
  },
  {
    id: 3,
    tag: 'Alquiler de bienes inmuebles',
    content: 'Alquiler de bienes inmuebles'
  },
  { id: 4, tag: 'Donación / Sorteo', content: 'Donación / Sorteo' },
  { id: 5, tag: 'Ingresos por regalía', content: 'Ingresos por regalía' },
  {
    id: 6,
    tag: 'Ingresos por trabajo dependiente',
    content: 'Ingresos por trabajo dependiente'
  },
  {
    id: 7,
    tag: 'Ingresos por trabajo independiente',
    content: 'Ingresos por trabajo independiente'
  },
  { id: 8, tag: 'Préstamos', content: 'Préstamos' },
  { id: 9, tag: 'Venta de bien mueble', content: 'Venta de bien mueble' },
  { id: 10, tag: 'Venta de bien inmueble', content: 'Venta de bien inmueble' },
  { id: 11, tag: 'Otro', content: 'Otro' }
]

export const userAccounts = [
  {
    id: 0,
    type: 'Ahorros',
    alias: 'Ahorros',
    currency: 'Soles',
    accountNumber: 19191354684485,
    bank: 'Banco de Credito del Perú'
  },
  {
    id: 1,
    type: 'Corriente',
    alias: 'Sueldo',
    currency: 'Soles',
    accountNumber: 19191354684486,
    bank: 'Interbank'
  },
  {
    id: 2,
    type: 'Ahorros',
    alias: 'Dolares',
    currency: 'Dolares',
    accountNumber: 19191354684492,
    bank: 'Banco de Credito del Perú'
  },
  {
    id: 3,
    type: 'Ahorros',
    alias: 'Ahorros 2',
    currency: 'Soles',
    accountNumber: 19191354684444,
    bank: 'Banco de Credito del Perú'
  },
  {
    id: 4,
    type: 'Ahorros',
    alias: 'Ahorros 3',
    currency: 'Soles',
    accountNumber: 19191354684444,
    bank: 'Banco de Credito del Perú'
  }
]

export const userTransac = [
  {
    id: 0,
    createdAt: new Date().toLocaleDateString(),
    currency: 'Soles',
    payOut: false,
    status: 'Iniciando Verificacion',
    accountNumber: 19191354684444
  },
  {
    id: 1,
    createdAt: new Date().toLocaleDateString(),
    currency: 'Soles',
    payOut: true,
    status: 'Pago Verificado',
    accountNumber: 19191354684444
  },
  {
    id: 2,
    createdAt: new Date().toLocaleDateString(),
    currency: 'Soles',
    payOut: true,
    status: 'Deposito Exitoso',
    accountNumber: 19191354684444
  },
  {
    id: 3,
    createdAt: new Date().toLocaleDateString(),
    currency: 'Soles',
    payOut: true,
    status: 'Deposito Exitoso',
    accountNumber: 19191354684444
  },
  {
    id: 4,
    createdAt: new Date().toLocaleDateString(),
    currency: 'Soles',
    payOut: true,
    status: 'Deposito Exitoso',
    accountNumber: 19191354684444
  }
]

export const ocupations = [
  { id: 0, value: 'Ama de casa' },
  { id: 1, value: 'Desempleado' },
  { id: 2, value: 'Empleado' },
  { id: 3, value: 'Empleador' },
  { id: 4, value: 'Estudiante' },
  { id: 5, value: 'Jubilado' },
  { id: 6, value: 'Miembro de las fuerzas armadas' },
  { id: 7, value: 'Obrero' },
  { id: 8, value: 'Trabajador de hogar' },
  { id: 9, value: 'Trabajador independiente' },
  { id: 10, value: 'No declara' }
]

import React from 'react'
import PropTypes from 'prop-types'
import UserForm from './UserForm'

const UserInfo = ({ history }) => {
  /* TODO: El componente se re-renderiza ya que estamos
  modificando el mismo estado que estamos cargando en el
  useForm, al detectar un cambio en las props renderiza el
  componente nuevamente.

  1. se intento separar el componente pero el componente
  padre tambien se esta renderizando nuevamente.

  2. Se agregara una ruta para mostrar pantalla de cambios
  existoso mientras se soluciona este re-render.
  */

  return <UserForm history={history} />
}

UserInfo.propTypes = {
  history: PropTypes.object
}

export default UserInfo

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Container,
  Headline,
  Subtitle
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons'

import lockImg from '../../assets/lock.svg'
import AuthContext from '../../context/Auth/AuthContext'
import { mapNewProfile, mapProfile } from '../../helpers/MapProfiles'
import AppContext from '../../context/App/AppContext'
import { useMutation } from '@apollo/client'
import { NEW_PERFIL_PERSON } from '../../apollo/queries'

const Wrapper = styled.div`
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  justify-content: ${({ center }) => (center ? 'center' : 'space-around')};
  margin: ${({ center }) => (center ? '0 auto' : 'space-around')};
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }

  button {
    @media screen and (max-width: 425px) {
      margin-bottom: 10px;
    }
  }
`

const VerifyImg = styled.img`
  max-width: 400px;
`

const RegisterVerify = ({ history }) => {
  const { setNewProfile } = useContext(AppContext)
  const { user, formValues } = useContext(AuthContext)

  const [newProfilePerson] = useMutation(NEW_PERFIL_PERSON)

  // const hRegisterWithVerify = () => {
  //   const payload = mapNewProfile(formValues, user.userId)
  //   console.log(payload)
  //   history.push('/auth/register/document', payload)
  // }

  useEffect(() => {
    if (Object.keys(formValues).length === 0) {
      history.push('/auth')
    }
  }, [])

  const handleNewProfile = async () => {
    const payload = mapNewProfile(formValues, user.userId)
    try {
      const GQLData = await newProfilePerson({
        variables: { input: payload }
      })
      const { CrearPerfil } = GQLData.data
      const profileMap = mapProfile(CrearPerfil)
      setNewProfile(profileMap)
      history.push('/auth/register/document', profileMap)
    } catch ({ graphQLErrors }) {
      console.log(graphQLErrors)
    }
  }

  return (
    <Container size="large" center>
      <Wrapper>
        <Headline>VERIFICACION DE IDENTIDAD</Headline>
        <Subtitle>
          Para esta Validacion solo necesitaras Tu documento de identidad con el
          que te has registrado.
        </Subtitle>
      </Wrapper>
      <Wrapper center>
        <VerifyImg src={lockImg} alt="" />
      </Wrapper>
      <Wrapper>
        <p>
          Al validar tu identidad nos ayudas a evitar la suplatancion de
          identidad y riesgos relacionados a tu informacion personal ademas
          contribuyes al esfuerzo de prevenir lavados de activos o actividades
          ilicitas.
        </p>
        <strong>
          Tu informacion solo sera utilizada con fines de seguridad y validacion
          de identidad.
        </strong>
      </Wrapper>
      <Wrapper flex>
        {/* <Button onClick={hRegisterWithoutVerify}>Hacerlo luego</Button> */}
        <Button onClick={handleNewProfile}>Validar ahora</Button>
      </Wrapper>
    </Container>
  )
}

RegisterVerify.propTypes = {
  history: PropTypes.object
}

export default RegisterVerify

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AuthContext from '../../context/Auth/AuthContext'

import { Title, IconLogOut, NavUserDropDown, NavUserBtn } from './Navbar.styles'
import { useHistory } from 'react-router'
import AppContext from '../../context/App/AppContext'

const NavbarDropdown = ({ isDropDownOpen, hDropDownOpen }) => {
  const history = useHistory()
  const { logoutAction } = useContext(AuthContext)
  const { selectedProfile, purgeProfile } = useContext(AppContext)

  const isPerson = selectedProfile.tipoPerfil === 0
  const infoPath = isPerson ? '/app/userInfo' : '/app/businessInfo'

  const hLogout = () => {
    purgeProfile()
    logoutAction()
    hDropDownOpen(false)
  }

  const handleOnClick = (path) => {
    hDropDownOpen(!isDropDownOpen)
    history.push(path)
  }

  return (
    <NavUserDropDown isOpen={isDropDownOpen}>
      <NavUserBtn onClick={() => handleOnClick('/app')}>
        <Title>Cambiar Perfil</Title>
      </NavUserBtn>
      <NavUserBtn onClick={() => handleOnClick(infoPath)}>
        <Title>Datos{isPerson ? ' Personales' : ' Jurídicos'}</Title>
      </NavUserBtn>
      <NavUserBtn onClick={() => handleOnClick('/app/changePassword')}>
        <Title>Cambiar Contraseña</Title>
      </NavUserBtn>
      <NavUserBtn onClick={() => handleOnClick('/app/refers')}>
        <Title>Referidos</Title>
      </NavUserBtn>
      <NavUserBtn onClick={hLogout}>
        <IconLogOut />
        <Title>Cerrar Sesion</Title>
      </NavUserBtn>
    </NavUserDropDown>
  )
}

NavbarDropdown.propTypes = {
  hDropDownOpen: PropTypes.func.isRequired,
  isDropDownOpen: PropTypes.bool.isRequired
}

export default NavbarDropdown

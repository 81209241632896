import React from 'react'

import {
  FooterCompany,
  FooterContainer,
  FooterHeader,
  FooterLogo,
  FooterSocial,
  FooterText,
  FooterWrapper,
  Icon,
  SocialTitle,
  SocialWrapper
} from './Footer.styles'
import FooterLinks from './FooterLinks'

import logo from '../../assets/logo.png'
import youtubeIcon from '../../assets/youtube.svg'
import facebookIcon from '../../assets/facebook.svg'
import linkedinIcon from '../../assets/linkedin.svg'
import instagramIcon from '../../assets/instagram.svg'

const Footer = () => {
  return (
    <FooterContainer id="visor">
      <FooterHeader>
        <FooterLogo src={logo} alt="Logo de Kambista" />
      </FooterHeader>
      <FooterWrapper>
        <FooterLinks />
      </FooterWrapper>
      <FooterSocial>
        <SocialTitle>Siguenos</SocialTitle>
        <SocialWrapper>
          <Icon src={facebookIcon} />
          <Icon src={instagramIcon} />
          <Icon src={linkedinIcon} />
          <Icon src={youtubeIcon} />
        </SocialWrapper>
      </FooterSocial>
      <FooterCompany>
        <FooterText>KtalCambio S.A.C. RUC 20601708141</FooterText>
        <FooterText>Todos los derechos reservados</FooterText>
      </FooterCompany>
    </FooterContainer>
  )
}

export default Footer

import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { RiUploadCloudLine } from 'react-icons/ri'
import { FaWindowClose, FaCheckCircle } from 'react-icons/fa'
import { DragZoneContainer } from './DragZone.styles'

const DragZone = ({ name, hOnChange }) => {
  const [isImg, setisImg] = useState(null)
  const [errors, setErrors] = useState({ ok: true })

  useEffect(() => {
    if (isImg) {
      const payload = {
        target: {
          name,
          value: isImg
        }
      }
      hOnChange(payload)
    }
  }, [isImg])

  const onDrop = useCallback((acceptedFiles) => {
    const type = acceptedFiles[0].type
    if (type !== 'image/jpeg' && type !== 'image/png') {
      return setErrors({
        ok: false,
        msg: 'Solo los formatos PNG y JPG son permitidos'
      })
    }

    setisImg(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const resIcon = errors.ok ? (
    <RiUploadCloudLine color="#9d9d9d" />
  ) : (
    <FaWindowClose color="red" />
  )

  const handleOnChange = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    if (validity.valid) {
      setisImg(file)
    }
  }

  return (
    <DragZoneContainer
      disabled={isImg}
      isDragActive={isDragActive}
      {...getRootProps()}
    >
      <input {...getInputProps()} onChange={handleOnChange} name={name} />
      {!isImg && resIcon}
      {isImg ? (
        <>
          <FaCheckCircle color="green" />
          <strong>{isImg.name}</strong>
        </>
      ) : !errors.ok ? (
        <p>{errors.msg}</p>
      ) : isDragActive ? (
        <p>Suelta el archivo aqui...</p>
      ) : (
        <>
          <span>Has click para seleccionar el archivo</span>
          <p>
            Arrastra y suelta el archivo aqui o has click para seleccionarlo
          </p>
        </>
      )}
    </DragZoneContainer>
  )
}

DragZone.propTypes = {
  name: PropTypes.string,
  hOnChange: PropTypes.func
}

export default DragZone

import {
  PURGE_PROFILE,
  SET_ADMIN_BANKACC,
  SET_DELETE_BANKACC,
  SET_EDIT_BANKACC,
  SET_INFO_PROFILE,
  SET_NEW_BANKACC,
  SET_NEW_PROFILE,
  SET_NEW_TRANSACTION,
  SET_PROFILE,
  SET_PROFILES,
  SET_TRANSACTIONS,
  SET_USER
} from './AppTypes'

const AppReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_USER: {
      return {
        ...state,
        profiles: [payload]
      }
    }

    case SET_PROFILES: {
      return {
        ...state,
        profiles: payload
      }
    }

    case SET_PROFILE: {
      return {
        ...state,
        selectedProfile: payload
      }
    }

    case PURGE_PROFILE: {
      return {
        profiles: [],
        selectedProfile: {}
      }
    }

    case SET_INFO_PROFILE: {
      const selectedProfile = state.selectedProfile
      return {
        ...state,
        selectedProfile: {
          ...selectedProfile,
          info: payload
        }
      }
    }

    case SET_NEW_PROFILE: {
      return {
        ...state,
        profiles: [...state.profiles, payload]
      }
    }

    case SET_ADMIN_BANKACC: {
      return {
        ...state,
        adminBankAccounts: [...payload]
      }
    }

    case SET_NEW_BANKACC: {
      const bankAccounts = state.selectedProfile.bankAccounts
      return {
        ...state,
        selectedProfile: {
          ...state.selectedProfile,
          bankAccounts: [...bankAccounts, payload]
        }
      }
    }

    case SET_EDIT_BANKACC: {
      const bankAccounts = state.selectedProfile.bankAccounts
      return {
        ...state,
        selectedProfile: {
          ...state.selectedProfile,
          bankAccounts: bankAccounts.map((acc) => {
            return acc.cuentaPerfilId === payload.cuentaPerfilId ? payload : acc
          })
        }
      }
    }

    case SET_DELETE_BANKACC: {
      const bankAccounts = state.selectedProfile.bankAccounts
      return {
        ...state,
        selectedProfile: {
          ...state.selectedProfile,
          bankAccounts: bankAccounts.filter(
            (acc) => acc.cuentaPerfilId !== payload
          )
        }
      }
    }

    case SET_TRANSACTIONS: {
      return {
        ...state,
        selectedProfile: {
          ...state.selectedProfile,
          transactions: [...payload]
        }
      }
    }

    case SET_NEW_TRANSACTION: {
      return {
        ...state,
        selectedProfile: {
          ...state.selectedProfile,
          transactions: [...state.selectedProfile.transactions, payload]
        }
      }
    }

    default:
      return state
  }
}

export default AppReducer

import React from 'react'
import { LoadingRing } from './Loading.styles'

const LoadingRings = () => {
  return (
    <LoadingRing>
      <div />
      <div />
      <div />
      <div />
    </LoadingRing>
  )
}

export default LoadingRings

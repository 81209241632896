import React, { useContext, useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useHistory } from 'react-router'
import AppContext from '../../context/App/AppContext'

import {
  Box,
  Wrapper,
  BoxImage,
  Headline,
  Container
} from '../../components/Auth/Auth.styles'
import avatar1 from '../../assets/avatar1.png'
import LoadingScreen from '../../components/Loading'
import AuthContext from '../../context/Auth/AuthContext'

const SelectProfile = () => {
  // TODO: usar history por props
  const history = useHistory()
  // COntexts
  const [isLoading, setisLoading] = useState(false)
  const { logoutAction } = useContext(AuthContext)
  const { profiles, setProfile, purgeProfile } = useContext(AppContext)

  useEffect(() => {
    return () => setisLoading(false)
  }, [])

  const hSelectedProfile = (profile) => {
    setisLoading(true)

    if (profile.info.estado === 1 || !profile.info.estado) {
      setisLoading(false)
      purgeProfile()
      logoutAction()
      history.push('/auth/register/document', profile)
      return
    }
    setisLoading(true)
    setProfile(profile)
    setisLoading(false)
    history.push('/app/transaction/new')
  }

  const handleAddProfile = () => {
    history.push('/app/profile')
  }

  if (isLoading) return <LoadingScreen />

  return (
    <Container size="large" center>
      <Headline expand>Elige un Perfil</Headline>
      <Wrapper>
        {profiles.map((profile) => (
          <Box key={profile.perfilId} onClick={() => hSelectedProfile(profile)}>
            <BoxImage src={avatar1} />
            <p>{profile.name}</p>
          </Box>
        ))}
        <Box onClick={handleAddProfile}>
          <FaPlus />
          <p>Agregar Perfil</p>
        </Box>
      </Wrapper>
    </Container>
  )
}

export default SelectProfile

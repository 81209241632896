import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FiSearch, FiCheck } from 'react-icons/fi'

import {
  Input,
  InputItem,
  Placeholer,
  FrontError
} from '../../components/Auth/Auth.styles'
import { getCuponByName } from '../../API'

const Search = styled(FiSearch)`
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 22px;
  color: #9d9d9d;

  transition: 0.2s ease;

  &:hover {
    color: #323232;
    cursor: pointer;
  }
`

const Check = styled(FiCheck)`
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 22px;
  color: #9d9d9d;

  transition: 0.2s ease;

  &:hover {
    color: #323232;
    cursor: pointer;
  }
`

const CuponField = ({ name, placeholder, value, setFormValues }) => {
  const [errors, setErrors] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [cuponName, setCuponName] = useState('')

  const handleSubmit = (codigo) => {
    if (cuponName.trim() === '') return

    getCuponByName(codigo).then(({ GetCodigoCupon }) => {
      if (GetCodigoCupon === null) {
        setIsValid(false)
        setFormValues({ ...value, cupon: {} })
        return setErrors('Cupon ingresado no es valido')
      }

      if (+new Date(GetCodigoCupon.fechaFinal) < +new Date()) {
        return setErrors('Cupon vencido')
      }
      setErrors('')
      setIsValid(true)
      setFormValues({ ...value, cupon: GetCodigoCupon })
    })
  }

  return (
    <InputItem>
      <Input
        name={name}
        type="text"
        autoComplete="off"
        value={cuponName}
        onChange={({ target }) => setCuponName(target.value)}
        error={errors.trim() !== ''}
        valid={cuponName.trim() !== ''}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleSubmit(e.target.value)
        }}
      />
      <Placeholer>{placeholder}</Placeholer>
      {!isValid ? (
        <Search onClick={() => handleSubmit(cuponName)} />
      ) : (
        <Check color="green" />
      )}
      <FrontError>{errors.trim() !== '' && errors}</FrontError>
    </InputItem>
  )
}

CuponField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default CuponField

import React, { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'

import { Input, InputItem, Placeholer, FrontError } from '../Auth/Auth.styles'

const ShowPassword = styled(BsEyeFill)`
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 22px;
  color: #9d9d9d;

  transition: 0.2s ease;

  &:hover {
    color: #323232;
    cursor: pointer;
  }
`

const HidePassword = styled(BsEyeSlashFill)`
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 22px;
  color: #9d9d9d;

  transition: 0.2s ease;

  &:hover {
    color: #323232;
    cursor: pointer;
  }
`

const InputPass = ({ name, hOnChange, state, errors, placeholder }) => {
  const [showPass, setShowPass] = useState(false)
  return (
    <InputItem>
      <Input
        name={name}
        type={showPass ? 'text' : 'password'}
        value={state[name]}
        onChange={hOnChange}
        valid={state[name].trim() !== ''}
        error={errors[name]?.msg}
      />
      <Placeholer>{placeholder}</Placeholer>
      {showPass ? (
        <ShowPassword onClick={() => setShowPass(!showPass)} />
      ) : (
        <HidePassword onClick={() => setShowPass(!showPass)} />
      )}
      <FrontError>{errors[name] && errors[name]?.msg}</FrontError>
    </InputItem>
  )
}

InputPass.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default InputPass

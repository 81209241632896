import {
  SOCIAL_REGISTER,
  PROFILE_REGISTER,
  EMAIL_REGISTER,
  LOGIN_ACTION,
  LOGOUT_ACTION,
  SET_REGISTER,
  PURGE_REGISTER,
  SET_USER_ACTION
} from './authTypes'

const AuthReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_USER_ACTION:
      return {
        ...state,
        user: payload
      }

    case LOGIN_ACTION:
      return {
        ...state,
        isAuth: true,
        user: payload
      }

    case LOGOUT_ACTION:
      return {
        ...state,
        user: null,
        isAuth: false
      }

    case SET_REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          formValues: {
            ...state.register.formValues,
            ...payload
          }
        }
      }

    case EMAIL_REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          mode: 'email'
        }
      }

    case SOCIAL_REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          mode: 'social',
          formValues: {
            ...state.register.formValues,
            ...payload
          }
        }
      }

    case PROFILE_REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          accountType: payload
        }
      }

    case PURGE_REGISTER:
      return {
        ...state,
        register: payload
      }

    default:
      return state
  }
}

export default AuthReducer

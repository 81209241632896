import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ChevronIcon,
  ItemBtnWrap,
  MenuBtn,
  MenuItem,
  SubMenu,
  SubMenuItem
} from './Accordion.styles'
import { Button } from '../Buttons/Buttons.styles'
import { useHistory } from 'react-router'
import { banks } from '../../pages/fakeData'

const AccountItem = ({ item, setAccountSelected }) => {
  const history = useHistory()
  const { alias, nroCuenta } = item
  const [isOpen, setIsOpen] = useState(false)

  const hDelete = () => {
    setAccountSelected({
      isOpen: true,
      account: item
    })
  }

  const hEdit = () => {
    history.push('/app/bankAccount', item)
  }

  const tipoMoneda = item.tipoMoneda === 0 ? 'PEN' : 'USD'
  const tipoCuenta = item.tipoCuenta === 0 ? 'Ahorros' : 'Corriente'
  const bankObj = banks.filter((b) => b.id === parseInt(item.banco))[0]

  return (
    <MenuItem>
      <MenuBtn onClick={() => setIsOpen(!isOpen)}>
        <strong>{alias}</strong>
        <ChevronIcon isOpen={isOpen} />
      </MenuBtn>
      <SubMenu isOpen={isOpen}>
        <SubMenuItem>
          <p>Moneda</p>
          <strong>{tipoMoneda}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Tipo de Cuenta</p>
          <strong>{tipoCuenta}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Numero de Cuenta</p>
          <strong>{nroCuenta}</strong>
        </SubMenuItem>
        <SubMenuItem>
          <p>Banco</p>
          <strong>{bankObj.bankAbrr}</strong>
        </SubMenuItem>
        <ItemBtnWrap>
          <Button background="#EC8A6F" onClick={hDelete}>
            Eliminar
          </Button>
          <Button background="#5B9DC2" onClick={hEdit}>
            Editar
          </Button>
        </ItemBtnWrap>
      </SubMenu>
    </MenuItem>
  )
}

AccountItem.propTypes = {
  item: PropTypes.object,
  setAccountSelected: PropTypes.func
}

export default AccountItem

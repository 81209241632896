import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Select,
  BtnsWrap,
  Headline,
  FrontError
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons/Buttons.styles'
import { AppContainer, AppForm, Wrapper } from '../../components/App/App.styles'

import useForm from '../../hooks/useForm'
import AppContext from '../../context/App/AppContext'
import InputText from '../../components/Inputs/InputText'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE_BUSINESS } from '../../apollo/queries'
// import { mapProfileBusiness } from '../../helpers/MapProfiles'

const BusinessInfo = ({ history }) => {
  const [errors] = useState({ ok: true })
  const { selectedProfile, setInfoProfile } = useContext(AppContext)
  const [formValues, hOnChange] = useForm(selectedProfile.info)

  const [updateProfileB] = useMutation(UPDATE_PROFILE_BUSINESS)

  const hOnSubmit = async (e) => {
    e.preventDefault()

    const { tipoPerfil, __typename, User, ...rest } = formValues
    await updateProfileB({ variables: { input: rest } })

    setInfoProfile(formValues)
    history.push('/app/success', { msg: '¡Datos Actualizados!' })
  }

  return (
    <AppContainer center>
      <Headline expand>Datos Jurídicos</Headline>
      <AppForm onSubmit={hOnSubmit}>
        <div>
          <Wrapper grid={2}>
            <InputText
              name="ruc"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="R.U.C."
              disabled
            />
            <InputText
              name="nombreComercial"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Razón Social"
              disabled
            />
          </Wrapper>
          <Wrapper grid={2}>
            <InputText
              name="alias"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Alias de la empresa"
            />
            <InputText
              name="emailFactura"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Correo Empresarial"
            />
            {/* <InputText
              name="country"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Pais"
              disabled
            />
            <InputText
              name="city"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Ciudad"
              disabled
            /> */}
          </Wrapper>
          <Wrapper grid={2}>
            <div>
              <Select
                name="sectorEconomico"
                value={formValues.sectorEconomico}
                onChange={hOnChange}
              >
                <option value="">Seleccione Sector económico</option>
                <option value="0">Agropecuario</option>
                <option value="1">Construcciones</option>
                <option value="2">
                  Manufactura - Procesos de recursos Primarios
                </option>
                <option value="3">Manufactura - Industria No Primaria</option>
                <option value="4">Minería e Hidrocarburos</option>
                <option value="5">Pesca</option>
                <option value="6">Servicios</option>
              </Select>
              <FrontError>
                {errors.ocupation && errors.ocupation?.msg}
              </FrontError>
            </div>
            <div>
              <Select
                name="actividadEconomica"
                value={formValues.actividadEconomica}
                onChange={hOnChange}
              >
                <option value="">Seleccione Actividad económica</option>
                <option value="0">Comercio Automotriz</option>
                <option value="1">Comercio de Arroz</option>
                <option value="2">Comercio al por mayor</option>
                <option value="3">Comercio al por menor</option>
              </Select>
              <FrontError>
                {errors.ocupation && errors.ocupation?.msg}
              </FrontError>
            </div>
          </Wrapper>
          <BtnsWrap>
            <Button onClick={() => history.push('/app/transaction/new')}>
              Cancelar
            </Button>
            <Button type="submit">Actualizar</Button>
          </BtnsWrap>
        </div>
      </AppForm>
    </AppContainer>
  )
}

BusinessInfo.propTypes = {
  history: PropTypes.object
}

export default BusinessInfo

import styled from 'styled-components'
import { Link } from 'react-router-dom'

// Containers, Forms, Wrappers
export const Container = styled.main`
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: ${({ center }) => (center ? 'center' : '')};
  padding-inline: 35px;
  margin-bottom: 100px;
  max-width: ${({ size }) => (size === 'large' ? '600px' : '350px')};

  @media screen and (max-width: 750px) {
    padding-bottom: ${({ size }) => (size === 'large' ? '100px' : '0')};
  }
`

export const Form = styled.form`
  background: white;
  width: 100%;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 360px) {
    width: 100%;
    border-radius: 0;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  @media screen and (max-width: 550px) {
    margin-top: 0px;
    /* flex-direction: column; */
    align-items: center;
  }
`

export const FullWidth = styled.div`
  width: 100%;
`

export const InputsWrapper = styled.div`
  /* margin-bottom: 20px; */
`

export const InputItem = styled.div`
  position: relative;
  width: 100%;
`

export const BoxContainer = styled.div`
  /* margin-top: -100px; */
  width: 100%;
`

// Tipografia H1...
export const Headline = styled.h1`
  color: #222;
  font-size: 38px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  margin-top: 80px;
  margin-bottom: ${({ expand }) => (expand ? '50px' : '0')};

  @media screen and (max-width: 360px) {
    font-size: 35px;
  }
`

export const Subtitle = styled.p`
  color: #222;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  margin-bottom: ${({ expand }) => (expand ? '50px' : '0')};
`

// Separadores
export const SocialHr = styled.div`
  height: 1px;
  width: 100%;
  background: #e2e2e2;
  margin-block: 20px;
  display: flex;
  justify-content: center;

  p {
    margin: 0;
    width: 200px;
    height: 20px;
    margin-top: -9px;
    background: #fff;
    text-align: center;
  }
`

// Input, Selects y Placeholders

export const Input = styled.input`
  border: 0;
  width: 100%;
  height: 55px;
  box-shadow: none;
  color: #222;
  line-height: 55px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px 0 0 13px;
  line-height: 5px;
  background: ${({ valid }) => (valid ? 'transparent' : '#e2e2e2')};
  border: 1px solid
    ${({ valid, error }) => {
      if (error) {
        return 'red'
      } else {
        return valid ? '#9d9d9d' : 'transparent'
      }
    }};

  ${({ valid }) => (valid ? '& + label {top: -17px; left: 6px;}' : '')}

  transition: 300ms ease-in-out;

  &:focus {
    outline: 0;
    background: transparent;
    border: 1px solid ${({ error }) => (error ? '#bc252a' : '#323232')};
  }

  &:focus + label {
    left: 6px;
    top: -17px;
  }

  &:disabled {
    background: #f2f2f2;
    pointer-events: none;
  }
`

export const Placeholer = styled.label`
  position: absolute;
  top: 0;
  left: 14px;
  top: ${({ valid }) => (valid ? '-17px' : '0')};
  left: ${({ valid }) => (valid ? '6px' : '14px')};
  color: #9d9d9d;
  user-select: none;
  line-height: 60px;
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;

  ${({ valid }) => (valid ? '& + label {top: -17px; left: 6px;}' : '')}
`

export const SelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  margin: 0;

  @media screen and (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
`

export const Select = styled.select`
  border: 0;
  width: 100%;
  height: 55px;
  box-shadow: none;
  color: #222;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0px 13px;
  background: transparent;
  border: 1px solid #9d9d9d;
  margin-top: ${({ withLabel }) => (withLabel ? '5px' : '')};
  transition: 300ms ease-in-out;

  &:focus {
    outline: 0;
    background: transparent;
    border: 1px solid #323232;
  }
`

export const SelectLabel = styled.label`
  font-weight: 500;
  margin-bottom: 5px;
`

// Errores
export const FrontError = styled.p`
  margin: 0;
  color: red;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
`

export const BackError = styled.p`
  margin: 0;
  color: red;
  height: 20px;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 5px;
  display: flex;

  svg {
    margin-right: 10px;
  }
`

// Links

export const UserVerify = styled.div`
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
    display: flex;
    margin-bottom: 5px;
  }

  svg {
    color: ${({ color }) => color};
    font-size: 25px;
  }

  p {
    margin: 0;
    margin-left: 10px;
    line-height: 25px;
    font-weight: 500;
    text-transform: uppercase;
  }

  a {
    margin-left: auto;
  }
`

export const LinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 20px;
`

export const AuthLink = styled(Link)`
  text-decoration: none;
  color: #222;
  margin-bottom: 5px;
  text-transform: uppercase;
`

export const Box = styled.div`
  width: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: 20px;

  p:nth-child(1) {
    font-size: 20px;
    margin: 0;
    margin-block: 10px;
  }

  svg {
    min-height: 117px;
    font-size: 35px;
    font-weight: 100;
    transition: transform 0.3s linear;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
`

export const BoxImage = styled.img`
  width: 100%;
  min-height: 114px;
  object-fit: contain;
  transition: transform 0.3s linear;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const OcupationContainer = styled.div`
  display: grid;
  place-items: center;
  margin-bottom: 40px;
  text-align: center;

  h1 {
    margin: 0;
  }
`

export const OcupationBtnWrap = styled.div`
  width: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export const DocumentWrap = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;

  @media screen and (max-width: 756px) {
    flex-direction: column;
    align-items: center;
  }
`

export const TransactionWrap = styled.div`
  width: 300px;

  p:nth-child(1) {
    font-size: 20px;
    text-align: center;
  }
`

export const BtnsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-block: 50px;
`

export const FullWidthMobile = styled.div`
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`

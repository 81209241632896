export const SET_USER = 'SET_USER'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_PROFILES = 'SET_PROFILES'
export const PURGE_PROFILE = 'PURGE_PROFILE'
export const SET_NEW_PROFILE = 'SET_NEW_PROFILE'
export const SET_INFO_PROFILE = 'SET_INFO_PROFILE'
export const SET_NEW_BANKACC = 'SET_NEW_BANKACC'
export const SET_ADMIN_BANKACC = 'SET_ADMIN_BANKACC'
export const SET_EDIT_BANKACC = 'SET_EDIT_BANKACC'
export const SET_PHOTOS_PROFILE = 'SET_PHOTOS_PROFILE'
export const SET_DELETE_BANKACC = 'SET_DELETE_BANKACC'
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS'
export const SET_NEW_TRANSACTION = 'SET_NEW_TRANSACTION'

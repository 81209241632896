import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Subtitle } from '../Auth/Auth.styles'
import TransactionItem from './TransactionItem'
import {
  AccordionImage,
  AccordionMenu,
  AccordionContainer
} from './Accordion.styles'

import missingImg from '../../assets/question_mark.svg'
import AppContext from '../../context/App/AppContext'
import LoadingRings from '../Loading/LoadingRing'
import Pagination from '../Pagination'

const Accordion = ({
  hasStatus = 0,
  loading,
  data,
  currentPage,
  numbersPages,
  setPage
}) => {
  // const dataArr = transactions.filter((item) => item.estado === hasStatus)
  const { selectedProfile, adminBankAccounts } = useContext(AppContext)

  /* eslint-disable */
  const titleEmpty =
    hasStatus === 0
      ? 'No hay Transacciones por verificar'
      : hasStatus === 1
      ? 'No hay Transacciones por pagar'
      : hasStatus === 2
      ? 'No hay Transacciones existosas'
      : 'No hay Transacciones anuladas'
  /* eslint-enable */

  if (loading) return <LoadingRings />

  const transactions = data?.GetTransaccionesPerfil?.data ?? []
  const dataArr = transactions.filter((item) => item.estado === hasStatus)

  return (
    <AccordionContainer>
      <Pagination
        currentPage={currentPage}
        numbersPages={numbersPages}
        setPage={setPage}
      />
      {dataArr.length !== 0 ? (
        <AccordionMenu>
          {dataArr.map((item) => (
            <TransactionItem
              key={item.transaccionId}
              item={item}
              adminBankAccounts={adminBankAccounts}
              bankAccounts={selectedProfile.bankAccounts}
            />
          ))}
        </AccordionMenu>
      ) : (
        <>
          <Subtitle expand>{titleEmpty}</Subtitle>
          <AccordionImage
            src={missingImg}
            alt="Illustracion de una chica viendo un signo de interrogacion"
          />
        </>
      )}
    </AccordionContainer>
  )
}

Accordion.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  hasStatus: PropTypes.number,
  currentPage: PropTypes.number,
  numbersPages: PropTypes.func,
  setPage: PropTypes.func
}

export default Accordion

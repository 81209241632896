import React from 'react'
import PropTypes from 'prop-types'

import { FrontError, InputItem, Select } from '../Auth/Auth.styles'

const InputSelect = ({ name, state, hOnChange, data = [] }) => {
  return (
    <InputItem>
      <Select name={name} value={state[name]} onChange={hOnChange}>
        <option value="0">DNI</option>
        <option value="1">Pasaporte</option>
        <option value="2">Carnet de Extranjeria</option>
      </Select>
      <FrontError></FrontError>
    </InputItem>
  )
}

InputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object)
}

export default InputSelect

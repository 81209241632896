import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import {
  Input,
  Select,
  Headline,
  BtnsWrap,
  FullWidth,
  InputItem,
  Placeholer,
  FrontError,
  FullWidthMobile
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons/Buttons.styles'
import { AppContainer, Wrapper } from '../../components/App/App.styles'

import { banks } from '../fakeData'
import useForm from '../../hooks/useForm'
import AppContext from '../../context/App/AppContext'
import { bankAccValid } from '../../helpers/formValidation'
import NewPContext from '../../context/NewProfile/NewPContext'

import { NEW_BANK_ACCOUNT, NEW_PERFIL_BUSINESS } from '../../apollo/queries'
import AuthContext from '../../context/Auth/AuthContext'

const NewBusinessBankA = ({ history, location }) => {
  const { user } = useContext(AuthContext)
  const { setNewProfile } = useContext(AppContext)
  const { purgeProfile } = useContext(NewPContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [formValues, hOnChange, setFormValues] = useForm({
    banco: '',
    alias: '',
    nroCuenta: '',
    tipoCuenta: '',
    tipoMoneda: '',
    ownership: false
  })

  const [newPB] = useMutation(NEW_PERFIL_BUSINESS)
  const [newBA] = useMutation(NEW_BANK_ACCOUNT)

  useEffect(() => {
    if (isDirty) {
      const validation = bankAccValid(formValues)
      setErrors(validation)
    }
  }, [formValues])

  const hOnCheck = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.checked
    })
  }

  const hOnChangeAcc = ({ target }) => {
    const value = target.value
    const account = banks.filter(({ id }) => id === parseInt(value))
    const { id, ...rest } = account[0]
    setFormValues({
      ...formValues,
      ...rest,
      banco: id
    })
  }

  const hOnSubmit = async () => {
    const validation = bankAccValid(formValues)
    setIsDirty(true)

    if (!validation.ok) return setErrors(validation)

    try {
      const GQLData = await newPB({
        variables: {
          input: {
            ...location.state,
            userId: user.userId,
            tipoPerfil: 1,
            ProvCodi: 200,
            DistCodi: 2000,
            DeparCodi: 100
          }
        }
      })
      console.log(GQLData.data.CrearPerfil)
      const { tipoPerfil, alias, perfilId } = GQLData.data.CrearPerfil
      const { ownership, bankAbrr, bank, ...rest } = formValues
      const newBAData = await newBA({
        variables: { input: { ...rest, perfilId } }
      })
      console.log(newBAData.data.CrearCuentaPerfil)
      const payload = {
        name: alias,
        tipoPerfil,
        perfilId,
        info: {
          ...GQLData.data.CrearPerfil
        },
        bankAccounts: [{ ...newBAData }]
      }
      setNewProfile(payload)
    } catch (error) {
      console.log(error)
    }

    purgeProfile()
    history.push('/app')
  }

  const {
    tipoCuenta,
    banco,
    alias,
    tipoMoneda,
    ownership,
    nroCuenta
  } = formValues

  return (
    <AppContainer>
      <Headline expand>Cuenta Bancaria Empresarial</Headline>
      <FullWidthMobile>
        <Wrapper grid={3}>
          <FullWidth>
            <Select name="tipoCuenta" value={tipoCuenta} onChange={hOnChange}>
              <option value="">Seleccione tipo de cuenta</option>
              <option value="0">Ahorro</option>
              <option value="1">Corriente</option>
            </Select>
            <FrontError>{errors.type && errors.type?.msg}</FrontError>
          </FullWidth>
          <FullWidth>
            <Select name="tipoMoneda" value={tipoMoneda} onChange={hOnChange}>
              <option value="">Seleccione moneda</option>
              <option value="0">Soles (S/)</option>
              <option value="1">Dolares ($)</option>
            </Select>
            <FrontError>{errors.currency && errors.currency?.msg}</FrontError>
          </FullWidth>
          <InputItem>
            <Input
              type="text"
              name="alias"
              value={alias}
              autoComplete="off"
              onChange={hOnChange}
              valid={alias.trim() !== ''}
            />
            <Placeholer>Alias</Placeholer>
            <FrontError>{errors.alias && errors.alias?.msg}</FrontError>
          </InputItem>
        </Wrapper>
        <Wrapper grid={2}>
          <FullWidth>
            <Select name="banco" value={banco} onChange={hOnChangeAcc}>
              {banks.map((bank) => (
                <option key={bank.id} value={String(bank.id)}>
                  {bank.bank}
                </option>
              ))}
            </Select>
            <FrontError>{errors.bank && errors.bank?.msg}</FrontError>
          </FullWidth>
          <InputItem>
            <Input
              type="text"
              autoComplete="off"
              name="nroCuenta"
              onChange={hOnChange}
              value={nroCuenta}
              valid={nroCuenta !== ''}
            />
            <Placeholer>N° de Cuenta</Placeholer>
            <FrontError>
              {errors.accountNumber && errors.accountNumber?.msg}
            </FrontError>
          </InputItem>
        </Wrapper>
        <div>
          <input
            id="ownership"
            type="checkbox"
            name="ownership"
            checked={ownership}
            onChange={hOnCheck}
          />
          <label htmlFor="ownership"> Declaro que esta cuenta es mia.</label>
          <FrontError>{errors.ownership && errors.ownership?.msg}</FrontError>
        </div>
      </FullWidthMobile>
      <BtnsWrap>
        <Button onClick={hOnSubmit}>Finalizar</Button>
      </BtnsWrap>
    </AppContainer>
  )
}

NewBusinessBankA.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default NewBusinessBankA

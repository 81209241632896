import styled from 'styled-components'

export const DetailsContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  margin-inline: 20px;
`

export const DetailsTable = styled.div`
  width: 100%;
`

export const DetailsHeadline = styled.h2`
  margin: 0;
  font-weight: 500;
`

export const DetailsTitle = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: ${({ bold }) => (bold ? 'bold' : '500')};
  text-align: ${({ center }) => (center ? 'center' : '')};
`

export const DetailsSup = styled.div`
  background: ${({ estado }) => {
    if (estado === 0) return '#95caee'
    if (estado === 1) return '#eed595'
    if (estado === 2) return '#95eec1'
    if (estado === 3) return '#faa7a7'

    return '#95caee'
  }};
  padding: 0.5rem 1rem;
  border-radius: 10px;

  p {
    margin: 0;
  }
`

export const DetailsRow = styled.div`
  width: 100%;
  height: ${({ title }) => (title ? '40px' : '')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ title }) => (title ? '20px' : '5px')};
`

export const TextMuted = styled.p`
  margin: 0;
  font-weight: 100;
`

export const TextBold = styled.p`
  margin: 0;
  font-weight: 500;
`

export const InfoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 960px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
`

export const DetailsImage = styled.img`
  width: 100%;
  margin-bottom: 50px;
`

export const SuccessComents = styled.div`
  width: 100%;
  margin-bottom: 50px;

  @media screen and (min-width: 960px) {
    width: 60%;
  }
`

export const BtnWrap = styled.div`
  display: flex;
  width: min(100%, 500px);
  justify-content: space-between;

  @media screen and (min-width: 960px) {
    margin-top: 100px;
  }
`

import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

import {
  Form,
  Headline,
  Subtitle,
  Container,
  DocumentWrap
} from '../../components/Auth/Auth.styles'

import useForm from '../../hooks/useForm'
import { Button } from '../../components/Buttons/Buttons.styles'

import AppContext from '../../context/App/AppContext'
import { documentValid } from '../../helpers/formValidation'
import DragZoneItem from '../../components/DragZone/DragZoneItem'
import AuthContext from '../../context/Auth/AuthContext'

import { UPDATE_PROFILE_PERSON } from '../../apollo/queries'

const NewPersonDocument = ({ history, location }) => {
  const { setProfile, setProfiles, profiles } = useContext(AppContext)
  const { loginAction } = useContext(AuthContext)
  const [errors, setErrors] = useState({ ok: true })
  const [isDirty, setIsDirty] = useState(false)
  const [state, hOnChange] = useForm({
    foto1: { name: '' },
    foto2: { name: '' }
  })

  const [UpdatePerfil] = useMutation(UPDATE_PROFILE_PERSON, {
    onCompleted: () => history.push('/app/transaction/new'),
    onError: (err) => console.log(err)
  })

  useEffect(() => {
    if (isDirty) {
      const validation = documentValid(state)
      setErrors(validation)
    }
  }, [state])

  const hOnSubmit = async (e) => {
    e.preventDefault()
    const validation = documentValid(state)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      const {
        User,
        name,
        lastName,
        middleName,
        motherLastName,
        __typename,
        email,
        foto1,
        foto2,
        ...rest
      } = location.state.info
      const GQLData = await UpdatePerfil({
        variables: {
          foto1: state.foto1,
          foto2: state.foto2,
          input: { ...rest, estado: 2 }
        }
      })
      const { foto1: newFoto1, foto2: newFoto2 } = GQLData.data.UpdatePerfil
      const selectedProfile = {
        ...location.state,
        info: {
          ...location.state.info,
          estado: 2,
          foto1: newFoto1,
          foto2: newFoto2
        }
      }
      loginAction(location.state.info.User)
      setProfile(selectedProfile)
      const updateProfiles = profiles.map((p) => {
        return p.perfilId === selectedProfile.perfilId ? selectedProfile : p
      })
      setProfiles(updateProfiles)
      history.push('/app/transaction/new')
    }
  }

  return (
    <Container size="large" center>
      <Headline>Documento</Headline>
      <Subtitle expand>Solo los formatos PNG y JPG son permitidos</Subtitle>
      <Form onSubmit={hOnSubmit}>
        <DocumentWrap>
          <DragZoneItem
            title="Frontal"
            name="foto1"
            hOnChange={hOnChange}
            errors={errors}
          />
          <DragZoneItem
            title="Reverso"
            name="foto2"
            hOnChange={hOnChange}
            errors={errors}
          />
        </DocumentWrap>
        <Button>Siguiente</Button>
      </Form>
    </Container>
  )
}

NewPersonDocument.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default NewPersonDocument

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Form,
  Headline,
  InputsWrapper,
  SelectWrapper
} from '../../components/Auth/Auth.styles'

import { Button } from '../../components/Buttons'
import InputText from '../../components/Inputs/InputText'
import InputDate from '../../components/Inputs/InputDate'

import useForm from '../../hooks/useForm'
import AuthContext from '../../context/Auth/AuthContext'
import { infoValid } from '../../helpers/formValidation'

const RegisterInfo = ({ history }) => {
  const { formValues, setRegAction } = useContext(AuthContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange] = useForm({
    name: formValues.name ?? '',
    lastName: '',
    middleName: '',
    motherLastName: '',
    birthday: new Date()
  })

  useEffect(() => {
    if (isDirty) {
      const validation = infoValid(state)
      setErrors(validation)
    }

    if (Object.keys(formValues).length === 0) {
      history.push('/auth')
    }
  }, [state])

  const hOnSubmit = (e) => {
    e.preventDefault()

    const validation = infoValid(state)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      setRegAction(state)
      history.push('/auth/register/ocupation')
    }
  }

  return (
    <Container size="large" center>
      <Headline expand>Informacion Personal</Headline>
      <Form onSubmit={hOnSubmit}>
        <InputsWrapper>
          <InputText
            name="name"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Nombre"
          />
          <InputText
            name="middleName"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Segundo Nombre"
          />
          <SelectWrapper>
            <InputText
              name="lastName"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Apellido Paterno"
            />
            <InputText
              name="motherLastName"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Apellido Materno"
            />
          </SelectWrapper>
          <InputDate
            name="birthday"
            value={state}
            hOnChange={hOnChange}
            errors={errors}
            required
          />
        </InputsWrapper>
        <Button type="submit">Siguiente</Button>
      </Form>
    </Container>
  )
}

RegisterInfo.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default RegisterInfo

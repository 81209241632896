import React from 'react'
import PropTypes from 'prop-types'

import DragZone from '.'
import { DragZoneWrap } from './DragZone.styles'
import { FrontError } from '../Auth/Auth.styles'

const DragZoneItem = ({ title, name, hOnChange, errors }) => {
  return (
    <DragZoneWrap>
      <h2>{title}</h2>
      <DragZone name={name} hOnChange={hOnChange} />
      <FrontError>{errors[name] && errors[name]?.msg}</FrontError>
    </DragZoneWrap>
  )
}

DragZoneItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired
}

export default DragZoneItem

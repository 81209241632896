import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  FrontError,
  InputItem,
  Select
} from '../../components/Auth/Auth.styles'
import { gql, useQuery } from '@apollo/client'

const DEPARTAMENTOS = gql`
  query GetDepartamentos {
    GetDepartamentos {
      DeparCodi
      DeparNom
    }
  }
`

const DepartamentoSelect = ({ state, hOnChange }) => {
  const [departamentos, setDepartamentos] = useState([])
  const { loading } = useQuery(DEPARTAMENTOS, {
    onCompleted: (data) => setDepartamentos(data.GetDepartamentos)
  })

  return (
    <InputItem>
      <Select
        name="DeparCodi"
        onChange={hOnChange}
        value={state.DeparCodi}
        disabled={loading}
      >
        <option value="">DEPARTAMENTO</option>
        {departamentos.map(({ DeparCodi, DeparNom }) => (
          <option key={DeparCodi} value={DeparCodi}>
            {DeparNom}
          </option>
        ))}
      </Select>
      <FrontError />
    </InputItem>
  )
}

DepartamentoSelect.propTypes = {
  state: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired
}

export default DepartamentoSelect

import React from 'react'
import PropTypes from 'prop-types'
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Info, InfoAmount, InfoCol, InfoTitle } from './Exchange.styles'

const initialState = {
  compra: 0,
  venta: 0
}

const InfoPrice = ({
  isSolFirst,
  hasCupon,
  currentExhangeRate,
  exhangeRate = initialState
}) => {
  const { compra, venta } = currentExhangeRate
  const { exchangeRatePEN, exchangeRateUSD } = exhangeRate
  const exchangeRateFormat = (rate) => {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    }).format(rate)
  }

  return (
    <Info>
      <InfoCol borderRight>
        <InfoTitle isActive={isSolFirst}>VENTA</InfoTitle>
        <InfoAmount isActive={isSolFirst} hasCupon={hasCupon}>
          <div>
            <p>{exchangeRateFormat(exchangeRatePEN)}</p>
            <p>{hasCupon && exchangeRateFormat(venta)}</p>
          </div>
          {/* {hasCupon && <FiChevronDown />} */}
        </InfoAmount>
      </InfoCol>
      <InfoCol>
        <InfoTitle isActive={!isSolFirst}>COMPRA</InfoTitle>
        <InfoAmount isActive={!isSolFirst} hasCupon={hasCupon}>
          <div>
            <p>{exchangeRateFormat(exchangeRateUSD)}</p>
            <p>{hasCupon && exchangeRateFormat(compra)}</p>
          </div>
        </InfoAmount>
      </InfoCol>
    </Info>
  )
}

InfoPrice.propTypes = {
  cupon: PropTypes.object,
  hasCupon: PropTypes.bool,
  isSolFirst: PropTypes.bool,
  exhangeRate: PropTypes.object,
  currentExhangeRate: PropTypes.object
}

export default InfoPrice

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FaUserCheck, FaUserTimes } from 'react-icons/fa'

import {
  BtnsWrap,
  FrontError,
  Headline,
  InputItem,
  Select,
  UserVerify
} from '../../components/Auth/Auth.styles'
import DistritosSelect from './DistritosSelect'
import ProvinciaSelect from './ProvinciaSelect'
import DepartamentoSelect from './DepartamentoSelect'
import AppContext from '../../context/App/AppContext'
import AuthContext from '../../context/Auth/AuthContext'
import InputDate from '../../components/Inputs/InputDate'
import InputText from '../../components/Inputs/InputText'
import { Button } from '../../components/Buttons/Buttons.styles'
import { AppContainer, AppForm, Wrapper } from '../../components/App/App.styles'

import { ocupations } from '../fakeData'
import useForm from '../../hooks/useForm'
import { UPDATE_PROFILE_PERSON } from '../../apollo/queries'
import { updateInfoValid } from '../../helpers/formValidation'

const UserForm = ({ history }) => {
  const { user } = useContext(AuthContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const { info, setInfoProfile } = useContext(AppContext)

  const [UpdatePerfil] = useMutation(UPDATE_PROFILE_PERSON)
  const [formValues, hOnChange, setFormValues] = useForm(info)

  useEffect(() => {
    if (isDirty) {
      const validation = updateInfoValid(formValues)
      setErrors(validation)
    }
  }, [formValues])

  const hOnCheck = ({ target }) => {
    const checked = target.checked ? '1' : '0'
    setFormValues({
      ...formValues,
      [target.name]: checked
    })
  }

  const hOnSubmit = async (formvalues) => {
    setIsDirty(true)

    const validation = updateInfoValid(formValues)
    if (!validation.ok) return setErrors(validation)

    const {
      User,
      name,
      email,
      foto1,
      foto2,
      DistCodi,
      ProvCodi,
      DeparCodi,
      distrito,
      provincia,
      departamento,
      lastName,
      __typename,
      middleName,
      motherLastName,
      ...rest
    } = formvalues
    const payload = {
      ...rest,
      direccion: '',
      userId: parseInt(user.userId),
      DistCodi: parseInt(DistCodi),
      ProvCodi: parseInt(ProvCodi),
      DeparCodi: parseInt(DeparCodi),
      nombres: name + ' ' + middleName,
      apellidos: lastName + ' ' + motherLastName
    }
    try {
      await UpdatePerfil({
        variables: { input: payload }
      })
      setInfoProfile({
        ...formvalues,
        DistCodi: parseInt(DistCodi),
        ProvCodi: parseInt(ProvCodi),
        DeparCodi: parseInt(DeparCodi)
      })
      history.push('/app/success', { msg: '¡Tus datos han sido actualizados!' })
    } catch (error) {
      console.log(error)
    }
  }

  const estado = formValues.estado ? formValues.estado : 1

  /* eslint-disable */
  const userIsVerify =
    estado === 0
      ? 'Inactivo'
      : estado === 1
      ? 'Sin Documento'
      : estado === 2
      ? 'Esperando Verificación'
      : 'Verifcado'
  /* eslint-enable */

  return (
    <AppContainer center>
      <Headline expand>Datos Personales</Headline>
      <AppForm>
        <div>
          <Wrapper grid={4}>
            <InputText
              name="name"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Nombre"
              uppercase
              disabled
            />
            <InputText
              name="middleName"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Segundo Nombre"
              uppercase
              disabled
            />
            <InputText
              name="lastName"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Apellido Paterno"
              uppercase
              disabled
            />
            <InputText
              name="motherLastName"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Apellido Materno"
              uppercase
              disabled
            />
          </Wrapper>
          <Wrapper grid={3}>
            <InputText
              name="email"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Correo"
              disabled
            />
            <>
              <InputDate
                name="fechaNacimento"
                value={formValues}
                errors={errors}
                autoComplete="off"
                hOnChange={hOnChange}
                disabled
              />
            </>
            <div>
              <Select
                name="ocupacion"
                value={formValues.ocupacion}
                onChange={hOnChange}
              >
                <option value="">Ocupación</option>
                {ocupations.map(({ id, value }) => (
                  <option key={id} value={id}>
                    {value}
                  </option>
                ))}
              </Select>
              <FrontError>
                {errors.ocupacion && errors.ocupacion?.msg}
              </FrontError>
            </div>
          </Wrapper>
          <Wrapper grid={3}>
            <InputItem>
              <Select
                name="tipoDocumento"
                value={formValues.tipoDocumento}
                onChange={hOnChange}
                disabled
              >
                <option value="0">DNI</option>
                <option value="1">Pasaporte</option>
                <option value="2">Carnet de Extranjeria</option>
              </Select>
              <FrontError></FrontError>
            </InputItem>
            <InputText
              name="nroDocumento"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Numero de documento"
              disabled
            />
            <InputText
              name="celular"
              type="text"
              errors={errors}
              state={formValues}
              hOnChange={hOnChange}
              placeholder="Telefono"
            />
          </Wrapper>
          <Wrapper grid={3}>
            <DepartamentoSelect state={formValues} hOnChange={hOnChange} />
            <ProvinciaSelect state={formValues} hOnChange={hOnChange} />
            <DistritosSelect state={formValues} hOnChange={hOnChange} />
          </Wrapper>
          <Wrapper grid={2}>
            <div>
              <input
                id="personaExpuesta"
                type="checkbox"
                name="personaExpuesta"
                checked={parseInt(formValues.personaExpuesta) === 1}
                onChange={hOnCheck}
              />
              <label htmlFor="personaExpuesta">
                {' '}
                ¿Es usted una Persona Expuesta Políticamente (P.E.P.)?
              </label>
              <FrontError />
            </div>
            <UserVerify>
              <div>
                {formValues.estado === 3 ? (
                  <FaUserCheck color="green" />
                ) : (
                  <FaUserTimes color="red" />
                )}
                <p>{userIsVerify}</p>
              </div>
              {!userIsVerify && (
                <NavLink
                  to={{
                    state: formValues,
                    pathname: '/app/profile/personDocument'
                  }}
                >
                  ¿Deseas verificar tu perfil?
                </NavLink>
              )}
            </UserVerify>
          </Wrapper>
          <BtnsWrap>
            <Button onClick={() => history.push('/app/transaction/new')}>
              Cancelar
            </Button>
            <Button type="button" onClick={() => hOnSubmit(formValues)}>
              Actualizar
            </Button>
          </BtnsWrap>
        </div>
      </AppForm>
    </AppContainer>
  )
}

UserForm.propTypes = {
  hOnSubmit: PropTypes.func,
  history: PropTypes.object
}

export default UserForm

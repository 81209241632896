import {
  PURGE_STATE,
  SET_BUSINESS_PROFILE,
  SET_PERSON_PROFILE
} from './NewPTypes'

export const initialState = {
  profileType: '', // personal o empresas
  personForm: {
    // Paso 1
    name: '',
    phone: '',
    lastName: '',
    birthday: '',
    middleName: '',
    motherLastName: '',
    documentNumber: '',
    documentType: 'dni',
    // Paso 2
    PEP: false,
    ocupation: '',
    // Paso 3
    documentFront: '',
    documentReverse: '',
    documentCountryExp: ''
  },
  businessForm: {
    // Paso 1
    RUC: '',
    sector: '',
    RUCName: '',
    activity: '',
    profileName: '',
    factureEmail: '',
    // Paso 2
    type: '',
    bank: '',
    alias: '',
    currency: '',
    ownership: false,
    accountNumber: ''
  }
}

const NewPReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_PERSON_PROFILE:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          ...payload
        }
      }

    case SET_BUSINESS_PROFILE:
      return {
        ...state,
        businessForm: {
          ...state.businessForm,
          ...payload
        }
      }

    case PURGE_STATE:
      return payload

    default:
      return state
  }
}

export default NewPReducer

import React from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { AuthSocialBtn, AuthSocialIcon } from './Buttons.styles'
import facebookIcon from '../../assets/facebook-white.svg'

const FacebookButton = ({ handleLogin, handleRegister }) => {
  const responseFacebook = async (response) => {
    if (response.name && response.userID) {
      const payload = {
        name: response.name.split(' ')[0],
        email: response.email,
        password: response.userID
      }

      const input = {
        tipoInicio: 2,
        email: response.email,
        password: response.userID
      }
      const { isRegister } = await handleLogin(input)
      console.log(isRegister)
      if (!isRegister) {
        await handleRegister({
          ...input,
          name: payload.name
        })
      }
    }
  }

  return (
    <FacebookLogin
      autoload={false}
      fields="name,email,picture"
      callback={responseFacebook}
      appId={process.env.REACT_APP_FB_ID}
      render={({ onClick }) => (
        <AuthSocialBtn
          type="button"
          onClick={onClick}
          background="#3B5998"
          color="#fff"
        >
          <AuthSocialIcon src={facebookIcon} alt="Logo de Google" />
          <p>Accede con Facebook</p>
        </AuthSocialBtn>
      )}
    />
  )
}

FacebookButton.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  handleRegister: PropTypes.func.isRequired
}

export default FacebookButton

import styled from 'styled-components'

export const AccordionContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d9d9d;
    border-radius: 10px;
  }
`

export const AccordionMenu = styled.div`
  width: 100%;
  min-height: 70vh;
  max-height: 70vh;
  overflow: auto;
  margin: 0 auto;
  padding-right: 2px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d9d9d;
    border-radius: 10px;
  }

  @media screen and (min-width: 960px) {
    width: 70%;
  }
`

export const AccordionItemWeb = styled.div`
  display: flex;
  flex-direction: column;
`

export const AccordionImage = styled.img`
  width: 250px;
  margin-bottom: 20px;
`

export const MenuItem = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
`

export const MenuBtn = styled.button`
  background: #01c794;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  border: 0;
  outline: 0;
  width: 100%;
  height: 55px;
  padding: 1rem 1.6rem;
  border-radius: 0.2rem;
  cursor: pointer;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.1);

  svg {
    font-size: 20px;
  }

  strong {
    font-weight: 600;
  }
`

export const MenuTitleWrap = styled.div`
  display: flex;

  p {
    margin: 0;
    margin-right: 5px;
    @media screen and (max-width: 468px) {
      display: none;
    }
  }

  strong {
    font-weight: 600;
  }
`

export const ChevronIcon = styled.div`
  height: 20px;
  transition: transform 0.3s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const SubMenu = styled.div`
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? '20em' : '0')};
  border-radius: 0.2rem;
  margin-inline: 5px;
  padding-top: ${({ isOpen }) => (isOpen ? '0.5rem' : '0')};
`

export const SubMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;

  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  &:last-of-type {
    border-bottom: 2px solid #01c794;
  }

  p {
    margin: 0;
  }

  strong {
    ${({ hasCupon }) => {
      if (hasCupon) {
        return 'text-decoration: line-through; color: #f10101;'
      }
    }}
  }
`

export const ItemBtnWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
`

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppContainer } from '../../components/App/App.styles'
import {
  FrontError,
  Headline,
  Input,
  InputItem,
  Placeholer,
  SelectWrapper,
  Subtitle
} from '../../components/Auth/Auth.styles'

import referIcon from '../../assets/refers.svg'
import { Button } from '../../components/Buttons'
import AuthContext from '../../context/Auth/AuthContext'

const ReferImage = styled.img`
  width: 180px;
  margin-bottom: 20px;
`

const ReferInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin-bottom: 20px;

  p {
    font-size: 20px;
  }

  span {
    margin-top: 20px;
    cursor: pointer;
    text-decoration: underline;
  }
`

const ReferPage = ({ history }) => {
  const { user } = useContext(AuthContext)
  const totalLucas = user.totalMontoLucas ?? 0

  return (
    <AppContainer>
      <Headline>Referidos</Headline>
      <Subtitle expand>Gana Recomendando la Web</Subtitle>
      <ReferImage src={referIcon} alt="" />
      <ReferInfo>
        <p>
          Recibe S/10 por cada amigo que haga su primer pedido con tu código.
        </p>
        <SelectWrapper>
          <InputItem>
            <Input value={`S/ ${totalLucas}`} valid={true} disabled />
            <Placeholer>Tus creditos</Placeholer>
            <FrontError />
          </InputItem>
          <InputItem>
            <Input value={user.codigoReferido} valid={true} disabled />
            <Placeholer>Tu Código</Placeholer>
            <FrontError />
          </InputItem>
        </SelectWrapper>
        <span>Ver Términos y Condiciones</span>
      </ReferInfo>
      <Button>Copiar Código</Button>
    </AppContainer>
  )
}

ReferPage.propTypes = {
  history: PropTypes.object
}

export default ReferPage

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NewProfile from '../pages/Profile/NewProfile'

import LoginPage from '../pages/Login/LoginPage'
import RegisterInfo from '../pages/Register/RegisterInfo'
import RegisterPage from '../pages/Register/RegisterPage'
import RegisterVerify from '../pages/Register/RegisterVerify'
import RegisterDocument from '../pages/Register/RegisterDocument'
import RegisterOcupation from '../pages/Register/RegisterOcupation'
import RegisterWithSocial from '../pages/Register/RegisterWithSocial'

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/auth/login" component={LoginPage} />
      {/* Ruta para seleccionar si seras usario RUC o NATURAL */}
      <Route exact path="/auth/register" component={NewProfile} />
      {/* Paso 1/5 Ruta para registro con email */}
      <Route path="/auth/register/email" component={RegisterPage} />
      {/* Paso 1/5 Ruta para registro con Google o Facebook */}
      <Route path="/auth/register/social" component={RegisterWithSocial} />
      {/* Paso 2/5 Ruta para registrar los datos personales del usuario */}
      <Route path="/auth/register/personalInfo" component={RegisterInfo} />
      {/* Paso 3/5 Ruta para registrar la ocupacion del usuario */}
      <Route path="/auth/register/ocupation" component={RegisterOcupation} />
      {/* Paso 4/5 Ruta para dar instrucciones al usario sobre la carga del document */}
      <Route path="/auth/register/verify" component={RegisterVerify} />
      {/* Paso 5/5 Ruta para cargar frontal y reverso del documento */}
      <Route path="/auth/register/document" component={RegisterDocument} />

      <Redirect to="/auth/login" />
    </Switch>
  )
}

export default AuthRouter

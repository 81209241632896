import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import NewPReducer from './NewPReducer'
import NewPContext from './NewPContext'
import {
  PURGE_STATE,
  SET_BUSINESS_PROFILE,
  SET_PERSON_PROFILE
} from './NewPTypes'

export const initialState = {
  profileType: '', // personal o empresas
  personForm: {
    // Paso 1
    tipoPerfil: '',
    nombres: '',
    apellidos: '',
    fechaNacimento: '',
    tipoDocumento: '',
    nroDocumento: '',
    celular: '',
    // Paso 2
    ocupacion: '',
    direccion: '',
    personaExpuesta: '',
    DeparCodi: 0,
    ProvCodi: 0,
    DistCodi: 0,
    userId: ''
  },
  businessForm: {
    // Paso 1
    RUC: '',
    sector: '',
    RUCName: '',
    activity: '',
    profileName: '',
    factureEmail: '',
    // Paso 2
    type: '',
    bank: '',
    alias: '',
    currency: '',
    ownership: false,
    accountNumber: ''
  }
}

const NewPState = ({ children }) => {
  const [state, dispatch] = useReducer(NewPReducer, initialState)

  //* Accion para cargar informacion al state person */
  const setPersonProfile = (payload) => {
    dispatch({ type: SET_PERSON_PROFILE, payload })
  }

  //* Accion para cargar informacion al state business */
  const setBusinessProfile = (payload) => {
    dispatch({ type: SET_BUSINESS_PROFILE, payload })
  }

  //* Accion para cargar informacion al state */
  const purgeProfile = () => {
    dispatch({ type: PURGE_STATE, payload: initialState })
  }

  const exports = {
    // State
    personForm: state.personForm,
    profileType: state.profileType,
    // Funciones
    purgeProfile,
    setPersonProfile,
    setBusinessProfile
  }

  return <NewPContext.Provider value={exports}>{children}</NewPContext.Provider>
}

NewPState.propTypes = {
  children: PropTypes.node.isRequired
}

export default NewPState

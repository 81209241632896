import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
// import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const SideContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  display: grid;
  align-items: center;
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-200%')};

  transition: 0.3s ease-in-out;
`

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const CloseIcon = styled(FaTimes)`
  color: #222;
`

export const SideWrapper = styled.div`
  color: #222;
  padding: 0;
`

export const SideMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(8, 80px);
  text-align: center;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(8, 60px);
  } ;
`

export const SideLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  letter-spacing: 0.1rem;
  text-decoration: none;
  list-style: none;
  color: #222;
  cursor: pointer;

  transition: 0.2s ease-in-out;

  &:hover {
    color: #9d9d9d;
  }
`

export const SideBtnWrap = styled.div`
  margin: 0 auto;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SideBtn = styled(LinkR)`
  width: 100%;
  padding: 1rem 1.5rem;
  background: transparent;
  font-size: 20px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  border-radius: 5px;
  border: 1px solid #9d9d9d;
  cursor: pointer;

  transition: background 0.3s linear;

  &:focus,
  &:hover {
    background: #e2e2e2;
  }
`

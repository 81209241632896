export const mapProfiles = (data, user, bankAccounts = []) => {
  const profilesMap = data.map((profile) => {
    const [name, ...rest] = profile.nombres.split(' ')
    const middleName = rest.join(' ')
    const [lastName, ...restLastName] = profile.apellidos.split(' ')
    const motherLastName = restLastName.join(' ')
    return {
      name,
      perfilId: profile.perfilId,
      tipoPerfil: profile.tipoPerfil,
      info: {
        ...profile,
        name,
        middleName,
        lastName,
        motherLastName,
        email: profile.User.email,
        DeparCodi: profile.DeparCodi,
        ProvCodi: profile.ProvCodi,
        DistCodi: profile.DistCodi
      },
      bankAccounts: [],
      transactions: []
    }
  })

  return profilesMap
}

export const mapProfilesBusiness = (data, user, bankAccounts = []) => {
  const profilesMap = data.map((profile) => {
    return {
      name: profile.alias,
      perfilId: profile.perfilId,
      tipoPerfil: profile.tipoPerfil,
      info: {
        ...profile
      },
      bankAccounts,
      transactions: []
    }
  })

  return profilesMap
}

export const mapProfileBusiness = (profile, user, bankAccounts = []) => {
  return {
    name: profile.alias,
    perfilId: profile.perfilId,
    tipoPerfil: profile.tipoPerfil,
    info: {
      ...profile
    },
    bankAccounts,
    transactions: []
  }
}

export const mapProfile = (profile, bankAccounts = []) => {
  const [name, ...rest] = profile.nombres.split(' ')
  const middleName = rest.join(' ')
  const [lastName, ...restLastName] = profile.apellidos.split(' ')
  const motherLastName = restLastName.join(' ')
  return {
    name,
    perfilId: profile.perfilId,
    tipoPerfil: profile.tipoPerfil,
    info: {
      ...profile,
      name,
      middleName,
      lastName,
      motherLastName,
      email: profile.User.email,
      DeparCodi: profile.DeparCodi,
      ProvCodi: profile.ProvCodi,
      DistCodi: profile.DistCodi
    },
    bankAccounts,
    transactions: []
  }
}

export const mapNewProfile = (personForm, userId) => {
  const hasMiddleName = personForm.middleName.trim() !== ''
  const hasMotherLastName = personForm.motherLastName.trim() !== ''

  return {
    tipoPerfil: 0,
    nombres: hasMiddleName
      ? `${personForm.name} ${personForm.middleName}`
      : personForm.name,
    apellidos: hasMotherLastName
      ? `${personForm.lastName} ${personForm.motherLastName}`
      : personForm.lastName,
    celular: personForm.celular,
    tipoDocumento: parseInt(personForm.tipoDocumento),
    nroDocumento: personForm.nroDocumento,
    fechaNacimento: personForm.birthday.toLocaleDateString('en'),
    ocupacion: personForm.ocupacion,
    direccion: '',
    personaExpuesta: String(personForm.personaExpuesta),
    userId: parseInt(userId),
    DeparCodi: 100,
    ProvCodi: 200,
    DistCodi: 2000
  }
}

import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'
import { useMutation, useQuery } from '@apollo/client'

import AppContext from '../../context/App/AppContext'

// import { userAccounts } from '../fakeData'
import { Headline, Subtitle } from '../../components/Auth/Auth.styles'
import { AppContainer } from '../../components/App/App.styles'
import AccountItem from '../../components/Accordion/AccountItem'
import { OutlineButton } from '../../components/Buttons/Buttons.styles'
import {
  AccordionImage,
  AccordionMenu
} from '../../components/Accordion/Accordion.styles'

import Modal from '../../components/Modal'
import { deleteBAStore } from '../../apollo/actions'
import missingImg from '../../assets/question_mark.svg'
import { DELETE_BANK_ACCOUNT, GET_BANK_ACCOUNTS } from '../../apollo/queries'
import LoadingRings from '../../components/Loading/LoadingRing'

const BankAccounts = ({ history }) => {
  const {
    selectedProfile: { perfilId },
    setDeleteBankAcc
  } = useContext(AppContext)
  const [accountSelected, setAccountSelected] = useState({
    isOpen: false,
    account: {
      accountNumber: 0
    }
  })

  const [deleteBankAcc] = useMutation(DELETE_BANK_ACCOUNT)
  const { loading, data } = useQuery(GET_BANK_ACCOUNTS, {
    variables: { perfilId }
  })

  const onClickCancel = () => {
    setAccountSelected({
      isOpen: false,
      account: {
        accountNumber: 0
      }
    })
  }

  const onClickAccept = () => {
    const cuentaPerfilId = accountSelected.account.cuentaPerfilId
    setDeleteBankAcc(cuentaPerfilId)
    deleteBankAcc({
      variables: { input: { cuentaPerfilId } },
      update: (store) => deleteBAStore(store, perfilId, cuentaPerfilId)
    })
    setAccountSelected({
      isOpen: false,
      account: {
        accountNumber: 0
      }
    })
  }

  const modalContent = `Seguro deseas eliminar el N° de Cuenta: ${accountSelected.account.nroCuenta}`

  return (
    <AppContainer>
      <Headline>Cuentas Bancarias</Headline>
      <OutlineButton onClick={() => history.push('/app/bankAccount')}>
        <FaPlus />
        <p>Agregar cuenta</p>
      </OutlineButton>
      {loading ? (
        <LoadingRings />
      ) : data.GetAllCuentaPerfil.length === 0 ? (
        <>
          <Subtitle expand>No posees cuentas registradas</Subtitle>
          <AccordionImage
            src={missingImg}
            alt="Illustracion de una chica viendo un signo de interrogacion"
          />
        </>
      ) : (
        <AccordionMenu>
          {data.GetAllCuentaPerfil.map((item) => (
            <AccountItem
              item={item}
              key={item.cuentaPerfilId}
              setAccountSelected={setAccountSelected}
            />
          ))}
        </AccordionMenu>
      )}
      <Modal
        isOpen={accountSelected.isOpen}
        content={modalContent}
        onClickCancel={onClickCancel}
        onClickAccept={onClickAccept}
      />
    </AppContainer>
  )
}

BankAccounts.propTypes = {
  history: PropTypes.object
}

export default BankAccounts

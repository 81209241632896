import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Headline,
  Container,
  InputsWrapper,
  SelectWrapper
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons'
import InputDate from '../../components/Inputs/InputDate'
import InputText from '../../components/Inputs/InputText'
import InputSelect from '../../components/Inputs/InputSelect'

import useForm from '../../hooks/useForm'
import NewPContext from '../../context/NewProfile/NewPContext'
import { NewPInfoValid } from '../../helpers/formValidation'

const NewPersonProfile = ({ history }) => {
  const { setPersonProfile } = useContext(NewPContext)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange] = useForm({
    celular: '',
    tipoDocumento: '0',
    nroDocumento: '',
    name: '',
    middleName: '',
    lastName: '',
    motherLastName: '',
    birthday: new Date()
  })

  useEffect(() => {
    if (isDirty) {
      const validation = NewPInfoValid(state)
      setErrors(validation)
    }
  }, [state])

  const hOnSubmit = (e) => {
    e.preventDefault()

    const validation = NewPInfoValid(state)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      setPersonProfile(state)
      history.push('/app/profile/personOcupation')
    }
  }

  return (
    <Container size="large" center>
      <Headline expand>Nuevo Perfil</Headline>
      <Form onSubmit={hOnSubmit}>
        <InputsWrapper>
          <InputText
            name="name"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Nombre"
          />
          <InputText
            name="middleName"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Segundo Nombre"
          />
          <SelectWrapper>
            <InputText
              name="lastName"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Apellido Paterno"
            />
            <InputText
              name="motherLastName"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Apellido Materno"
            />
          </SelectWrapper>
          <InputDate
            name="birthday"
            value={state}
            hOnChange={hOnChange}
            errors={errors}
          />
          <SelectWrapper>
            <InputSelect
              name="tipoDocumento"
              state={state}
              hOnChange={hOnChange}
            />
            <InputText
              name="nroDocumento"
              type="number"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Documento"
            />
          </SelectWrapper>
          <InputText
            name="celular"
            type="number"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Telefono"
          />
        </InputsWrapper>
        <Button type="submit">Siguiente</Button>
      </Form>
    </Container>
  )
}

NewPersonProfile.propTypes = {
  history: PropTypes.object
}

export default NewPersonProfile

import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Box,
  // BoxContainer,
  BoxImage,
  Container,
  FrontError,
  Headline,
  Wrapper
} from '../../components/Auth/Auth.styles'
import AuthContext from '../../context/Auth/AuthContext'

import personIcon from '../../assets/person.svg'
import businessIcon from '../../assets/business.svg'

const NewProfile = () => {
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const { register, profileRegAction } = useContext(AuthContext)

  const handlePerson = () => {
    if (!user) {
      const payload = { type: 'person' }
      profileRegAction(payload)
      return history.push(`/auth/register/${register.mode}`)
    }

    if (user) {
      return history.push('/app/profile/person')
    }
  }

  const handleBusiness = () => {
    if (!user) return setIsOpen(!isOpen)
    return history.push('/app/profile/business')
  }

  return (
    <Container size="large" center>
      <Headline expand>Tipo de Perfil</Headline>
      <Wrapper>
        <Box onClick={handlePerson}>
          <BoxImage src={personIcon} />
          <h2>PERSONAL</h2>
          <FrontError />
        </Box>
        <Box onClick={handleBusiness}>
          <BoxImage src={businessIcon} />
          <h2>EMPRESARIAL</h2>
          <FrontError>
            {isOpen &&
              'Antes de crear tu perfil empresa, crea tu perfil de persona natural.'}
          </FrontError>
        </Box>
      </Wrapper>
    </Container>
  )
}

export default NewProfile

import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker'
import { FrontError, InputItem, Placeholer } from '../Auth/Auth.styles'

const InputDate = ({ name, value, hOnChange, errors, ...rest }) => {
  const target = (date) => ({
    target: {
      name,
      value: date
    }
  })

  return (
    <InputItem>
      <DatePicker
        format="dd/MM/yyyy"
        name={name}
        value={value[name]}
        onChange={(date) => hOnChange(target(date))}
        {...rest}
      />
      <Placeholer valid>Fecha de Nacimiento</Placeholer>
      <FrontError>{errors[name] && errors[name]?.msg}</FrontError>
    </InputItem>
  )
}

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired
}

export default InputDate

import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation login($input: loginInput!) {
    login(input: $input) {
      email
      estado
      userId
      apiToken
      codigoReferido
      totalMontoLucas
      codigoRefInvitado
    }
  }
`

export const CREATE_USER = gql`
  mutation CrearUsuario($input: UserInput!) {
    CrearUsuario(input: $input) {
      email
      userId
      apiToken
    }
  }
`

export const GET_PERFILES_PERSONA = gql`
  query {
    GetUserPerfilesPersona {
      perfilId
      tipoPerfil
      tipoDocumento
      nroDocumento
      nombres
      apellidos
      foto1
      foto2
      celular
      fechaNacimento
      ocupacion
      direccion
      personaExpuesta
      DeparCodi
      ProvCodi
      DistCodi
      estado

      User {
        userId
        tipoInicio
        tipoUsuario
        email
        estado
        apiToken
        codigoReferido
        totalMontoLucas
        codigoRefInvitado
      }
    }

    GetUserPerfilesEmpresa {
      perfilId
      tipoPerfil
      ruc
      nombreComercial
      alias
      emailFactura
      sectorEconomico
      actividadEconomica
      DeparCodi
      ProvCodi
      DistCodi
      estado

      User {
        userId
        tipoInicio
        tipoUsuario
        email
        estado
        apiToken
        codigoReferido
        totalMontoLucas
        codigoRefInvitado
      }
    }
  }
`

export const NEW_PERFIL_PERSON = gql`
  mutation CrearPerfil($input: PerfilesInput!, $foto1: Upload, $foto2: Upload) {
    CrearPerfil(input: $input, foto1: $foto1, foto2: $foto2) {
      perfilId
      tipoPerfil
      tipoDocumento
      nroDocumento
      nombres
      apellidos
      foto1
      foto2
      celular
      fechaNacimento
      ocupacion
      direccion
      DeparCodi
      ProvCodi
      DistCodi
      estado

      User {
        userId
        tipoInicio
        tipoUsuario
        email
        estado
        suscripcion
        apiToken
        codigoReferido
        totalMontoLucas
        codigoRefInvitado
      }
    }
  }
`

export const NEW_PERFIL_BUSINESS = gql`
  mutation CrearPerfil($input: PerfilesInput!) {
    CrearPerfil(input: $input) {
      perfilId
      tipoPerfil
      ruc
      nombreComercial
      alias
      emailFactura
      sectorEconomico
      actividadEconomica
      DeparCodi
      ProvCodi
      DistCodi
    }
  }
`

export const UPDATE_PROFILE_PERSON = gql`
  mutation UpdatePerfil(
    $input: PerfilesInput!
    $foto1: Upload
    $foto2: Upload
  ) {
    UpdatePerfil(input: $input, foto1: $foto1, foto2: $foto2) {
      perfilId
      foto1
      foto2
    }
  }
`

export const UPDATE_PROFILE_BUSINESS = gql`
  mutation UpdatePerfil($input: PerfilesInput!) {
    UpdatePerfil(input: $input) {
      perfilId
    }
  }
`

export const GET_PROFILE_BY_ID = gql`
  query GetIdPerfilesPersona($perfilId: Int) {
    GetIdPerfilesPersona(perfilId: $perfilId) {
      perfilId
      tipoPerfil
      tipoDocumento
      nroDocumento
      nombres
      apellidos
      foto1
      foto2
      celular
      fechaNacimento
      ocupacion
      direccion
      personaExpuesta
      DeparCodi
      ProvCodi
      DistCodi

      User {
        userId
        tipoInicio
        email
        estado
        apiToken
        codigoReferido
        totalMontoLucas
        codigoRefInvitado
      }
    }
  }
`

export const GET_BANK_ACCOUNTS = gql`
  query GetAllCuentaPerfil($perfilId: Int) {
    GetAllCuentaPerfil(perfilId: $perfilId) {
      cuentaPerfilId
      tipoMoneda
      tipoCuenta
      alias
      banco
      nroCuenta
    }
  }
`

export const GET_BANK_ACCOUNTS_ADMIN = gql`
  query {
    GetAllCuentaAdministrador {
      nombre
      nroCuenta
      tipoCuenta
      tipoMoneda
      cuentaAdmId
    }
  }
`

export const NEW_BANK_ACCOUNT = gql`
  mutation CrearCuentaPerfil($input: cuentaPerfilInput!) {
    CrearCuentaPerfil(input: $input) {
      cuentaPerfilId
    }
  }
`

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteCuentaPerfil($input: cuentaPerfilInput!) {
    DeleteCuentaPerfil(input: $input)
  }
`

export const EDIT_BANK_ACCOUNT = gql`
  mutation UpdateCuentaPerfil($input: cuentaPerfilInput!) {
    UpdateCuentaPerfil(input: $input) {
      cuentaPerfilId
    }
  }
`

export const GET_TRANSACTIONS_BYPROFILE = gql`
  query GetTransaccionesPerfil(
    $number_paginate: Int
    $page: Int
    $perfilId: Int
    $estado: String
  ) {
    GetTransaccionesPerfil(
      number_paginate: $number_paginate
      page: $page
      perfilId: $perfilId
      estado: $estado
    ) {
      NroItems
      data {
        transaccionId
        tipoTransaccion
        totalTransaccion
        valorMonedaActual
        cantidadMonedaCambiar
        origenFondos
        estado
        nroTransaccion
        cuentaAdmId
        cuentaPerfilId
        cuponId
        montoCupon
        codigoCupon
        created_at

        cuentaAdministrador {
          cuentaAdmId
          nombre
          tipoCuenta
          tipoMoneda
          nroCuenta
        }

        cuentaPerfil {
          cuentaPerfilId
          tipoMoneda
          tipoCuenta
          alias
          banco
          nroCuenta
        }
      }
    }
  }
`

export const NEW_TRANSACTION = gql`
  mutation CrearTransaccion($input: transaccionesInput!) {
    CrearTransaccion(input: $input) {
      transaccionId
      created_at
      valorMonedaActual
      totalTransaccion
      cuentaAdministrador {
        cuentaAdmId
        nombre
        tipoCuenta
        tipoMoneda
        nroCuenta
      }
      cuentaPerfil {
        cuentaPerfilId
        tipoMoneda
        tipoCuenta
        alias
        banco
        nroCuenta
      }
    }
  }
`

export const CHANGE_PASS = gql`
  mutation CambiarContrasenaUsuario($input: CambiarContrasenaInput!) {
    CambiarContrasenaUsuario(input: $input) {
      userId
    }
  }
`

export const GET_EXCHANGE_RATE = gql`
  query GetMonedaCambio {
    GetMonedaCambio {
      valorMonedaActualId
      exchangeRateUSD
      exchangeRatePEN
    }
  }
`

export const GET_CUPON_BYNAME = gql`
  query GetCodigoCupon($codigo: String) {
    GetCodigoCupon(codigo: $codigo) {
      cuponId
      nombre
      codigo
      unidades
      monto
      estado
      fechaInicial
      fechaFinal
    }
  }
`

import React from 'react'
import { LoadingContainer, LoadingLogo, LoadingRing } from './Loading.styles'

import logo from '../../assets/logo.png'

const LoadingScreen = () => {
  return (
    <LoadingContainer>
      <div>
        <LoadingLogo>
          <img src={logo} alt="Logo Kambista" />
        </LoadingLogo>
        <LoadingRing>
          <div />
          <div />
          <div />
          <div />
        </LoadingRing>
      </div>
    </LoadingContainer>
  )
}

export default LoadingScreen

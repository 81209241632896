import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Headline,
  BtnsWrap,
  TransactionWrap,
  FullWidth,
  Select,
  FrontError,
  SelectLabel
} from '../../components/Auth/Auth.styles'
import CuponField from './CuponField'
import Exchange from '../../components/Exchange'
import SelectBankAccUser from './SelectBankAccUser'
import SelectBankAccAdmin from './SelectBankAccAdmin'
import { Button } from '../../components/Buttons/Buttons.styles'
import { AppForm, AppContainer } from '../../components/App/App.styles'

import { originFunds } from '../fakeData'
import { newtransactionValid } from '../../helpers/formValidation'

const initialState = {
  cuponId: '',
  cupon: {},
  estado: 0,
  createdAt: Date.now(),
  origenFondos: '',
  amountToBuy: '',
  amountToPay: '100',
  exchangeRate: '',
  tipoTransaccion: 2,
  cuentaPerfil: {
    cuentaPerfilId: 0
  },
  cuentaAdmin: {
    cuentaAdmId: 0
  }
}

const NewTransaction = ({ history, location }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [formValues, setFormValues] = useState(() => {
    if (location.state) return location.state
    return initialState
  })

  useEffect(() => {
    if (isDirty) {
      const validation = newtransactionValid(formValues)
      setErrors(validation)
    }
  }, [formValues])

  const handleExchange = (values) => {
    setIsDirty(false)
    setFormValues({
      ...formValues,
      ...values,
      cuentaAdmin: {
        cuentaAdmId: 0
      },
      cuentaPerfil: {
        cuentaPerfilId: 0
      }
    })
  }

  const hOnChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    })
  }

  const hOnSubmit = (e) => {
    e.preventDefault()

    const validation = newtransactionValid(formValues)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      const hasCupon = Object.keys(formValues.cupon).length !== 0
      const payload = {
        ...formValues,
        cuponId: hasCupon ? formValues.cupon.cuponId : ''
      }

      console.log(payload)

      history.push('/app/transaction/details', payload)
    }
  }
  // tipoTransaccion: 0 = PEN - USD / 1 = USD - PEN
  const hOnChangeAcc = ({ target }, accs = []) => {
    const value = target.value
    const [account] = accs.filter((acc) => {
      return acc.cuentaPerfilId === value
    })
    setFormValues({
      ...formValues,
      [target.name]: account
    })
  }

  const hOnChangeAccAdmin = ({ target }, accs = []) => {
    const value = target.value
    const [account] = accs.filter((acc) => {
      return acc.cuentaAdmId === value
    })
    setFormValues({
      ...formValues,
      [target.name]: account
    })
  }

  return (
    <AppContainer>
      <Headline expand>Nueva Transaccion</Headline>
      <AppForm>
        <TransactionWrap>
          <p>Completa los siguientes campos sobre tus cuentas.</p>
          <SelectBankAccAdmin
            errors={errors}
            formValues={formValues}
            hOnChange={hOnChangeAccAdmin}
          />
          <SelectBankAccUser
            errors={errors}
            formValues={formValues}
            hOnChange={hOnChangeAcc}
          />
          <FullWidth>
            <SelectLabel>Origen de los fondos</SelectLabel>
            <Select
              withLabel
              name="origenFondos"
              value={formValues.origenFondos}
              onChange={hOnChange}
            >
              {originFunds.map(({ id, tag, content }) => (
                <option key={id} value={id}>
                  {content}
                </option>
              ))}
            </Select>
            <FrontError>
              {errors.origenFondos && errors.origenFondos?.msg}
            </FrontError>
          </FullWidth>
          <CuponField
            name="cuponId"
            value={formValues}
            setFormValues={setFormValues}
            placeholder="Ingresa tu cupon"
          />
        </TransactionWrap>
        <TransactionWrap>
          <p>Realiza tu cotización modificando el primer campo.</p>
          <Exchange displayInfo value={formValues} OnChange={handleExchange} />
        </TransactionWrap>
      </AppForm>
      <BtnsWrap>
        <Button type="button" onClick={hOnSubmit}>
          Siguiente
        </Button>
      </BtnsWrap>
    </AppContainer>
  )
}

NewTransaction.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default NewTransaction

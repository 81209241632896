import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaClock, FaCheck, FaMoneyCheckAlt, FaTimes } from 'react-icons/fa'

const FilterContainer = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  display: grid;
  border-radius: 5px;
  background: #f3f3f3;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 10px;
`

const FilterBtn = styled.button`
  border: 0;
  outline: 0;
  background: ${({ active }) => (active ? '#e2e2e2' : 'transparent')};
  padding-block: 1rem;
  cursor: pointer;

  transition: background 0.3s;

  p {
    margin: 0;
    @media screen and (max-width: 468px) {
      display: none;
    }
  }

  svg {
    font-size: 20px;
  }

  &:hover {
    background: #e2e2e2;
  }
`

const Filterbar = ({ hasStatus, setHasStatus }) => {
  const hOnClick = (status) => {
    setHasStatus(status)
  }

  return (
    <FilterContainer>
      <FilterBtn active={hasStatus === 0} onClick={() => hOnClick(0)}>
        <FaClock />
        <p>Verificando</p>
      </FilterBtn>
      <FilterBtn active={hasStatus === 1} onClick={() => hOnClick(1)}>
        <FaMoneyCheckAlt />
        <p>Pagando</p>
      </FilterBtn>
      <FilterBtn active={hasStatus === 2} onClick={() => hOnClick(2)}>
        <FaCheck />
        <p>Exitosas</p>
      </FilterBtn>
      <FilterBtn active={hasStatus === 3} onClick={() => hOnClick(3)}>
        <FaTimes />
        <p>Anuladas</p>
      </FilterBtn>
    </FilterContainer>
  )
}

Filterbar.propTypes = {
  hasStatus: PropTypes.number,
  setHasStatus: PropTypes.func
}

export default Filterbar

import React from 'react'
import PropTypes from 'prop-types'

import {
  DetailsImage,
  DetailsTitle,
  DetailsContainer,
  SuccessComents
} from '../../components/Details/Detail.styles'
import { Headline } from '../../components/Auth/Auth.styles'
import { AppContainer } from '../../components/App/App.styles'
import { Button } from '../../components/Buttons/Buttons.styles'

import sendIcon from '../../assets/send.svg'
import { numberFormat } from '../../helpers/numberFormat'

const SuccessTransaction = ({ history, location }) => {
  const hSuccess = () => {
    history.push('/app/transaction/records')
  }

  const { transaccionId, tipoTransaccion, totalTransaccion } = location.state

  console.log(location.state)

  const isPEN = tipoTransaccion === 2

  return (
    <AppContainer center>
      <Headline>Solicitud de Verificacion enviada</Headline>
      <DetailsContainer>
        <DetailsImage
          src={sendIcon}
          alt="illustracion de una persona sosteniendo una carta"
        />
        <DetailsTitle center>Codigo de transaccion</DetailsTitle>
        <DetailsTitle center bold>
          # {transaccionId}
        </DetailsTitle>
      </DetailsContainer>
      <SuccessComents>
        <DetailsTitle center>
          Estamos procesando tu transaccion, tan pronto la verifiquemos te
          estaremos depositando la cantidad de{' '}
          {numberFormat(totalTransaccion, !isPEN)} a tu cuenta.
        </DetailsTitle>
      </SuccessComents>
      <Button onClick={hSuccess}>Ver mis operaciones</Button>
    </AppContainer>
  )
}

SuccessTransaction.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default SuccessTransaction

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  FrontError,
  InputItem,
  Select
} from '../../components/Auth/Auth.styles'
import { gql, useLazyQuery } from '@apollo/client'

const DISTRITOS = gql`
  query GetDistritos($ProCode: String) {
    GetDistritos(ProCode: $ProCode) {
      DistCodi
      DistNom
      UbiDisCodi
    }
  }
`

const DistritosSelect = ({ state, hOnChange }) => {
  const [distritos, setDistritos] = useState([])
  const [GetDistritos, { loading }] = useLazyQuery(DISTRITOS, {
    onCompleted: (data) => setDistritos(data.GetDistritos)
  })

  useEffect(() => {
    GetDistritos({ variables: { ProCode: state.ProvCodi } })
  }, [state.ProvCodi, state.DeparCodi])

  return (
    <InputItem>
      <Select
        name="DistCodi"
        value={state.DistCodi}
        onChange={hOnChange}
        disabled={loading}
      >
        <option value="">DISTRITO</option>
        {distritos.map(({ DistCodi, DistNom }) => (
          <option key={DistCodi} value={DistCodi}>
            {DistNom}
          </option>
        ))}
      </Select>
      <FrontError />
    </InputItem>
  )
}

DistritosSelect.propTypes = {
  state: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired
}

export default DistritosSelect

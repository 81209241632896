import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import {
  FrontError,
  FullWidth,
  Select,
  SelectLabel
} from '../../components/Auth/Auth.styles'
import { GET_BANK_ACCOUNTS_ADMIN } from '../../apollo/queries'

const SelectBankAccAdmin = ({ hOnChange, formValues, errors }) => {
  const { loading, data } = useQuery(GET_BANK_ACCOUNTS_ADMIN)
  const baAdmin = data ? data.GetAllCuentaAdministrador : []

  const accountsFilter = useMemo(() => {
    return baAdmin.filter(({ tipoMoneda }) => {
      return tipoMoneda + 1 === formValues.tipoTransaccion
    })
  }, [formValues.tipoTransaccion, data])

  return (
    <FullWidth>
      <SelectLabel>¿Desde qué banco nos envías tu dinero?</SelectLabel>
      <Select
        withLabel
        name="cuentaAdmin"
        onChange={(e) => hOnChange(e, baAdmin)}
        value={formValues.cuentaAdmin.cuentaAdmId}
        disabled={loading}
      >
        <option value="">Selecciona el Banco</option>
        {data &&
          accountsFilter.map(({ cuentaAdmId, nombre }) => (
            <option key={cuentaAdmId} value={cuentaAdmId}>
              {nombre}
            </option>
          ))}
      </Select>
      <FrontError>{errors.cuentaAdmin && errors.cuentaAdmin?.msg}</FrontError>
    </FullWidth>
  )
}

SelectBankAccAdmin.propTypes = {
  errors: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired
}

export default SelectBankAccAdmin

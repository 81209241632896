import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  NavUsericon,
  NavUserMenu,
  NavUserName,
  NavUserMenuIcon
} from './Navbar.styles'

import NavbarDropdown from './NavbarDropdown'

const NavbarUser = ({ name }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  return (
    <NavUserMenu>
      <NavUsericon
        alt="Placeholder image"
        src="https://avatars.githubusercontent.com/u/15892787?s=60&v=4"
      />
      <NavUserName>{name}</NavUserName>
      <NavUserMenuIcon onClick={() => setIsDropDownOpen(!isDropDownOpen)} />
      <NavbarDropdown
        isDropDownOpen={isDropDownOpen}
        hDropDownOpen={setIsDropDownOpen}
      />
    </NavUserMenu>
  )
}

NavbarUser.propTypes = {
  name: PropTypes.string.isRequired
}

export default NavbarUser

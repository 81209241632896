export const exchangeFormat = (count) => {
  const options = {
    maximumFractionDigits: 3
    // minimumFractionDigits: 2
  }
  const res = new Intl.NumberFormat('en-US', options).format(count)
  const decenas = res.split('.')[0]
  const decimal = res.split('.')[1] ?? '00'
  const decimas = decimal[0] ?? 0
  const centesimas = decimal[1] ?? 0
  return `${decenas}.${decimas}${centesimas}`
}

export const currencyFormat = (amount = 0, isSol = true) => {
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
    // currency: isSol ? 'PEN' : 'USD',
    // style: 'currency',
    // currencySign: 'accounting'
  }
  const res = Intl.NumberFormat('en-US', options).format(amount)

  return res
}

export const numberFormat = (amount = 0, isSol = true) => {
  const options = {
    maximumFractionDigits: 3,
    minimumFractionDigits: 2
  }
  const res = Intl.NumberFormat('en-US', options).format(amount)
  const decenas = res.split('.')[0]
  const decimal = res.split('.')[1] ?? '00'
  const decimas = decimal[0] ?? 0
  const centesimas = decimal[1] ?? 0
  const total = `${decenas}.${decimas}${centesimas}`
  return `${isSol ? 'S/ ' : '$ '} ${total}`
}

export const exchangeRateFormat = (rate) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3
  }).format(rate)
}

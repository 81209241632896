import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import AppContext from '../../context/App/AppContext'

import Accordion from '../../components/Accordion'
import Filterbar from '../../components/FilterBar/Filterbar'
import { Headline } from '../../components/Auth/Auth.styles'
import { AppContainer } from '../../components/App/App.styles'
import { OutlineButton } from '../../components/Buttons/Buttons.styles'
import { useQuery } from '@apollo/client'
import { GET_TRANSACTIONS_BYPROFILE } from '../../apollo/queries'

const TransactionRecord = ({ history }) => {
  const [page, setPage] = useState(1)
  const [hasStatus, setHasStatus] = useState(0)
  const { selectedProfile } = useContext(AppContext)

  const { loading, data } = useQuery(GET_TRANSACTIONS_BYPROFILE, {
    variables: {
      page,
      estado: hasStatus,
      number_paginate: 50,
      perfilId: selectedProfile.perfilId
    }
  })

  const numbersPages = () => {
    if (data) {
      const nroItems = data.GetTransaccionesPerfil.NroItems

      if (nroItems > 50) return nroItems % 50
      return 1
    }
    return 0
  }

  return (
    <AppContainer>
      <Headline>Transacciones Realizadas</Headline>
      <OutlineButton onClick={() => history.push('/app/transaction/new')}>
        <FaPlus />
        <p>Nueva Transaccion</p>
      </OutlineButton>

      <Filterbar hasStatus={hasStatus} setHasStatus={setHasStatus} />
      <Accordion
        data={data}
        loading={loading}
        hasStatus={hasStatus}
        currentPage={page}
        numbersPages={numbersPages}
        setPage={setPage}
      />
    </AppContainer>
  )
}

TransactionRecord.propTypes = {
  history: PropTypes.object.isRequired
}

export default TransactionRecord

import { GET_BANK_ACCOUNTS, GET_TRANSACTIONS_BYPROFILE } from './queries'

export const newTransactionStore = (store, data, perfilId) => {
  const variables = {
    page: 1,
    perfilId,
    number_paginate: 50
  }
  const { GetTransaccionesPerfil: previusData } = store.readQuery({
    query: GET_TRANSACTIONS_BYPROFILE,
    variables
  })

  store.writeQuery({
    query: GET_TRANSACTIONS_BYPROFILE,
    variables,
    data: {
      GetTransaccionesPerfil: {
        ...previusData,
        data: [data.CrearTransaccion, ...previusData.data]
      }
    }
  })
}

export const newBAStore = (store, data, perfilId) => {
  const { GetAllCuentaPerfil: previusData } = store.readQuery({
    query: GET_BANK_ACCOUNTS,
    variables: { perfilId }
  })

  store.writeQuery({
    query: GET_BANK_ACCOUNTS,
    variables: { perfilId },
    data: {
      GetAllCuentaPerfil: [data.CrearCuentaPerfil, ...previusData]
    }
  })
}

export const editBAStore = (store, data, perfilId) => {
  const { GetAllCuentaPerfil: previusData } = store.readQuery({
    query: GET_BANK_ACCOUNTS,
    variables: { perfilId }
  })

  store.writeQuery({
    query: GET_BANK_ACCOUNTS,
    variables: { perfilId },
    data: {
      GetAllCuentaPerfil: previusData.map((acc) => {
        return acc.cuentaPerfilId === data.cuentaPerfilId ? data : acc
      })
    }
  })
}

export const deleteBAStore = (store, perfilId, cuentaId) => {
  const { GetAllCuentaPerfil: data } = store.readQuery({
    query: GET_BANK_ACCOUNTS,
    variables: { perfilId }
  })

  store.writeQuery({
    query: GET_BANK_ACCOUNTS,
    variables: { perfilId },
    data: {
      GetAllCuentaPerfil: data.filter(({ cuentaPerfilId }) => {
        return cuentaPerfilId !== cuentaId
      })
    }
  })
}

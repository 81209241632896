import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

// Vista para seleccionar el tipo de perfil
import NewProfile from '../pages/Profile/NewProfile'
import SelectProfile from '../pages/Profile/SelectProfile'
// Vista para ver historial de transacciones
import TransactionRecord from '../pages/Transaction/TransactionRecord'
// Vista para ver las cuentas bancarias
import BankAccounts from '../pages/BankAccounts/BankAccounts'
import AddBankAccount from '../pages/BankAccounts/AddBankAccount'
// Vista para actualizar datos personales
import UserInfo from '../pages/UserInfo/UserInfo'
// Vista para actualizar datos juridicos
import BusinessInfo from '../pages/BusinessInfo/BusinessInfo'
// Vista para ver tu codigo de referido
import ReferPage from '../pages/Refers/ReferPage'
// Proceso para generar Transaccion
import NewTransaction from '../pages/Transaction/NewTransaction'
import DetailsTransaction from '../pages/Transaction/DetailsTransaction'
import SuccessTransaction from '../pages/Transaction/SuccessTransaction'
// Creacion de Nuevos perfiles Naturales
import NewPersonVerify from '../pages/NewProfilePerson/NewPersonVerify'
import NewPersonProfile from '../pages/NewProfilePerson/NewPersonProfile'
import NewPersonDocument from '../pages/NewProfilePerson/NewPersonDocument'
import NewPersonOcupation from '../pages/NewProfilePerson/NewPersonOcupation'
// Creacion de Nuevos perfiles Empresariales
import ChangePassword from '../pages/ChangePassword/ChangePassword'
import NewBusinessBankA from '../pages/NewProfileBusiness/NewBusinessBankA'
import NewBusinessProfile from '../pages/NewProfileBusiness/NewBusinessProfile'
import ChangeSuccess from '../components/ChangeSuccess'

const AppRouter = () => {
  return (
    <>
      <Switch>
        {/* Ruta para seleccionar un perfil */}
        <Route exact path="/app" component={SelectProfile} />
        {/* Ruta para crear nuevo perfil */}
        <Route exact path="/app/profile" component={NewProfile} />
        {/* Rutas sobre las cuentas de banco */}
        <Route path="/app/bankAccounts" component={BankAccounts} />
        {/* Rutas para editar o crear Cuentas bancarias */}
        <Route path="/app/bankAccount" component={AddBankAccount} />
        {/* Ruta para los referidos */}
        <Route path="/app/refers" component={ReferPage} />
        {/* Ruta para actualizar datos personales */}
        <Route path="/app/userInfo" component={UserInfo} />
        {/* Ruta para actualizar datos juridicos */}
        <Route path="/app/businessInfo" component={BusinessInfo} />
        {/* Ruta para las transacciones */}
        <Route path="/app/transaction/new" component={NewTransaction} />
        <Route path="/app/transaction/details" component={DetailsTransaction} />
        <Route path="/app/transaction/success" component={SuccessTransaction} />
        <Route path="/app/transaction/records" component={TransactionRecord} />
        {/* Ruta para crear nuevo perfil Natural */}
        <Route path="/app/profile/person" component={NewPersonProfile} />
        <Route
          component={NewPersonOcupation}
          path="/app/profile/personOcupation"
        />
        <Route
          component={NewPersonVerify}
          path="/app/profile/personVerification"
        />
        <Route
          component={NewPersonDocument}
          path="/app/profile/personDocument"
        />

        {/* Ruta para crear nuevo perfil EMpresarial */}
        <Route path="/app/profile/business" component={NewBusinessProfile} />
        <Route path="/app/profile/businessBankA" component={NewBusinessBankA} />

        {/* Ruta para cambiar contraseña */}
        <Route path="/app/changePassword" component={ChangePassword} />

        {/* Ruta para mostrar cambios exitosos */}
        <Route path="/app/success" component={ChangeSuccess} />

        <Redirect to="/app/transaction/new" />
      </Switch>
    </>
  )
}

export default AppRouter

export const getBankAccounts = async (perfilId) => {
  const res = await fetch(
    'https://apicasadecambio.softaki.com/public/graphql',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          query {
            GetAllCuentaAdministrador {
              nombre
              nroCuenta
              tipoCuenta
              tipoMoneda
              cuentaAdmId
            }
          }
      `
      })
    }
  )

  const { data } = await res.json()
  return data
}

export const getCuponByName = async (name) => {
  const res = await fetch(
    'https://apicasadecambio.softaki.com/public/graphql',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
        query {
          GetCodigoCupon(codigo: "${name}"){
            cuponId
            nombre
            codigo
            unidades
            monto
            estado
            fechaInicial
            fechaFinal
          }
        }
      `
      })
    }
  )

  const { data } = await res.json()
  return data
}

export const getProfiles = async (token) => {
  const res = await fetch(
    'https://apicasadecambio.softaki.com/public/graphql',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: `
          query {
            GetUserPerfilesPersona {
              perfilId
              tipoPerfil
              tipoDocumento
              nroDocumento
              nombres
              apellidos
              foto1
              foto2
              celular
              fechaNacimento
              ocupacion
              direccion
              personaExpuesta
              DeparCodi
              ProvCodi
              DistCodi
              estado
        
              User {
                userId
                tipoInicio
                tipoUsuario
                email
                estado
                codigoReferido
                apiToken
              }
            }
        
            GetUserPerfilesEmpresa {
              perfilId
              tipoPerfil
              ruc
              nombreComercial
              alias
              emailFactura
              sectorEconomico
              actividadEconomica
              DeparCodi
              ProvCodi
              DistCodi
              estado
        
              User {
                userId
                tipoInicio
                tipoUsuario
                email
                estado
                codigoReferido
                apiToken
              }
            }
          }
      `
      })
    }
  )

  const { data } = await res.json()
  return data
}

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { FaTimesCircle } from 'react-icons/fa'

import {
  Form,
  AuthLink,
  Headline,
  SocialHr,
  LinkItems,
  Container,
  FullWidth,
  BackError,
  InputsWrapper
} from '../../components/Auth/Auth.styles'
import InputPass from '../../components/Inputs/InputPass'
import InputText from '../../components/Inputs/InputText'
import { Button, GoogleButton, FacebookButton } from '../../components/Buttons'

import { getProfiles } from '../../API'
import useForm from '../../hooks/useForm'
import LoadingScreen from '../../components/Loading'
import AppContext from '../../context/App/AppContext'
import { logValid } from '../../helpers/formValidation'
import AuthContext from '../../context/Auth/AuthContext'
import { LOGIN } from '../../apollo/queries'
// import { backendErrors } from '../../helpers/backenkErrors'
import { mapProfiles, mapProfilesBusiness } from '../../helpers/MapProfiles'

const LoginPage = ({ history }) => {
  // Contexts
  const Auth = useContext(AuthContext)
  const { setProfiles } = useContext(AppContext)
  // states
  const [isDirty, setIsDirty] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange] = useForm({
    email: '',
    password: ''
  })

  const [login] = useMutation(LOGIN, {
    // onError: ({ graphQLErrors }) => {
    //   setIsLoading(false)
    //   graphQLErrors.forEach((err) => {
    //     const errorsObj = backendErrors(err.debugMessage)
    //     setErrors(errorsObj)
    //     console.info(`login: ${err.debugMessage}`)
    //   })
    // }
  })

  useEffect(() => {
    if (isDirty) {
      const validation = logValid(state)
      setErrors(validation)
    }
  }, [state])

  const hOnSubmit = async (e) => {
    e.preventDefault()
    setIsDirty(true)
    const validation = logValid(state)
    if (!validation.ok) return setErrors(validation)

    await handleLogin({ ...state, tipoInicio: 1 })
  }

  const handleLogin = async (input) => {
    let isRegister = true
    setIsLoading(true)
    try {
      const GQLData = await login({
        variables: { input }
      })
      if (GQLData) {
        const loginData = GQLData.data?.login
        const profiles = await getProfiles(loginData.apiToken)
        const profilesP = mapProfiles(profiles.GetUserPerfilesPersona)
        const profilesB = mapProfilesBusiness(profiles.GetUserPerfilesEmpresa)
        setProfiles([...profilesP, ...profilesB])
        Auth.loginAction(loginData)
      }
    } catch ({ graphQLErrors }) {
      console.log(graphQLErrors[0].debugMessage)
      if (graphQLErrors) {
        if (graphQLErrors[0].debugMessage === 'NO_EXISTE') {
          isRegister = false
          setErrors({
            ok: false,
            backendErr: { msg: 'Correo o contraseña invalido' }
          })
        }
        if (graphQLErrors[0].debugMessage === 'CONTRASEÑA_INCORRECTA') {
          setErrors({
            ok: false,
            backendErr: { msg: 'Correo o contraseña invalido' }
          })
        }
        if (graphQLErrors[0].debugMessage === 'INICIE_CORREO') {
          setErrors({
            ok: false,
            backendErr: { msg: 'Debe iniciar sesion con su correo' }
          })
        }
        if (graphQLErrors[0].debugMessage === 'INICIE_GOOGLE') {
          setErrors({
            ok: false,
            backendErr: { msg: 'Debe iniciar sesion con GOOGLE' }
          })
        }
        if (graphQLErrors[0].debugMessage === 'INICIE_FACEBOOK') {
          setErrors({
            ok: false,
            backendErr: { msg: 'Debe iniciar sesion con FACEBOOK' }
          })
        }
      }
    }

    setIsLoading(false)

    return { isRegister }
  }

  const handleRegister = async (profileObj) => {
    const payload = {
      estado: 1,
      tipoUsuario: 2,
      suscripcion: 1,
      email: profileObj.email,
      password: profileObj.password,
      tipoInicio: profileObj.tipoInicio
    }
    Auth.setRegAction({
      name: profileObj.name,
      email: profileObj.email
    })
    history.push('/auth/register/social', payload)
  }

  const hRegisterEmail = () => {
    Auth.emailRegAction()
  }

  if (isLoading) return <LoadingScreen />

  return (
    <Container center>
      <Headline expand>Iniciar sesión</Headline>
      <BackError>
        {errors.backendErr && (
          <>
            <FaTimesCircle />
            {errors.backendErr?.msg}
          </>
        )}
      </BackError>
      <Form onSubmit={hOnSubmit}>
        <InputsWrapper>
          <InputText
            name="email"
            type="email"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Correo"
          />
          <InputPass
            name="password"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Contraseña"
          />
        </InputsWrapper>
        <Button type="submit">Iniciar Sesion</Button>
      </Form>
      <FullWidth>
        <SocialHr>
          <p>Si no posees cuenta</p>
        </SocialHr>
        <GoogleButton
          handleLogin={handleLogin}
          handleRegister={handleRegister}
        />
        <FacebookButton
          handleLogin={handleLogin}
          handleRegister={handleRegister}
        />
      </FullWidth>
      <LinkItems>
        <AuthLink to="/auth/register" onClick={hRegisterEmail}>
          Crear Cuenta
        </AuthLink>
      </LinkItems>
    </Container>
  )
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired
}

export default LoginPage

import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'

import AppState from './context/App/AppState'
import AuthState from './context/Auth/AuthState'

import RootRouter from './routers/RootRouter'

import client from './apollo/config'

const App = () => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <AuthState>
          <AppState>
            <RootRouter />
          </AppState>
        </AuthState>
      </ApolloProvider>
    </Router>
  )
}

export default App

const NO_EXISTE = 'NO_EXISTE'
const INICIE_CORREO = 'INICIE_CORREO'
const INICIE_GOOGLE = 'INICIE_GOOGLE'
const INICIE_FACEBOOK = 'INICIE_FACEBOOK'
const CONTRASEÑA_INCORRECTA = 'CONTRASEÑA_INCORRECTA'

export const backendErrors = (debugMessage) => {
  switch (debugMessage) {
    case NO_EXISTE:
      return {
        ok: false,
        email: { msg: '' },
        backendErr: { msg: 'Correo o contraseña invalida' }
      }

    case CONTRASEÑA_INCORRECTA:
      return {
        ok: false,
        email: { msg: '' },
        backendErr: { msg: 'Correo o contraseña invalida' }
      }

    case INICIE_CORREO:
      return {
        ok: false,
        backendErr: { msg: 'Debe iniciar sesion con su correo' }
      }

    case INICIE_GOOGLE:
      return {
        ok: false,
        backendErr: { msg: 'Debe iniciar con Google' }
      }

    case INICIE_FACEBOOK:
      return {
        ok: false,
        backendErr: { msg: 'Debe iniciar con Facebook' }
      }

    default:
      return {
        ok: false,
        backendErr: { msg: 'Por favor contactar con el administrador' }
      }
  }
}

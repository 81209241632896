import React from 'react'
import PropTypes from 'prop-types'

import { Input, InputItem, Placeholer, FrontError } from '../Auth/Auth.styles'

const InputText = ({
  name,
  type,
  hOnChange,
  state,
  errors,
  placeholder,
  uppercase,
  ...rest
}) => {
  return (
    <InputItem>
      <Input
        {...rest}
        name={name}
        type={type}
        autoComplete="off"
        value={uppercase ? state[name].toUpperCase() : state[name]}
        onChange={hOnChange}
        error={errors[name]}
        valid={state[name].trim() !== ''}
      />
      <Placeholer>{placeholder}</Placeholer>
      <FrontError>{errors[name] && errors[name]?.msg}</FrontError>
    </InputItem>
  )
}

InputText.propTypes = {
  uppercase: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hOnChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default InputText

import styled from 'styled-components'

export const CSContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const CSLogo = styled.div`
  width: 300px;
`

import React, { useContext } from 'react'
import { useHistory } from 'react-router'

import {
  Icon,
  SideMenu,
  SideLink,
  CloseIcon,
  SideWrapper,
  SideBtnWrap,
  SideContainer
} from './Sidebar.style'

import PropTypes from 'prop-types'
import AuthContext from '../../context/Auth/AuthContext'
import { Button } from '../Buttons/Buttons.styles'
import AppContext from '../../context/App/AppContext'

const Sidebar = ({ isOpen, setIsOpen }) => {
  const history = useHistory()
  const { logoutAction } = useContext(AuthContext)
  const { selectedProfile, purgeProfile } = useContext(AppContext)

  const handleOpen = () => setIsOpen(!isOpen)

  const handleLogout = () => {
    purgeProfile()
    logoutAction()
  }

  const isPerson = selectedProfile.tipoPerfil === 0
  const isAuth = Object.keys(selectedProfile).length !== 0
  const infoPath = isPerson ? '/app/userInfo' : '/app/businessInfo'

  return (
    <SideContainer isOpen={isOpen} onClick={handleOpen}>
      <Icon onClick={handleOpen}>
        <CloseIcon />
      </Icon>
      <SideWrapper>
        <SideMenu>
          {isAuth ? (
            <>
              <SideLink onClick={handleOpen} to="/app/bankAccounts">
                Cuentas
              </SideLink>
              <SideLink onClick={handleOpen} to="/app/transaction/records">
                Historial
              </SideLink>
              <SideLink onClick={handleOpen} to="/app/refers">
                Referidos
              </SideLink>
              <SideLink onClick={handleOpen} to={infoPath}>
                Datos{isPerson ? ' Personales' : ' Jurídicos'}
              </SideLink>
              <SideLink onClick={handleOpen} to="/app/changePassword">
                Cambiar Contraseña
              </SideLink>
              <SideLink onClick={handleOpen} to="/app">
                Seleccionar Perfil
              </SideLink>
              <SideLink onClick={handleOpen} to="/app/transaction/new">
                Nueva Transaccion
              </SideLink>
            </>
          ) : (
            <>
              <SideLink onClick={handleOpen} to="/">
                Inicio
              </SideLink>
              <SideLink onClick={handleOpen} to="/about">
                Nosotros
              </SideLink>
              <SideLink onClick={handleOpen} to="/blog">
                Blog
              </SideLink>
              <SideLink onClick={handleOpen} to="/enterprise">
                Empresa
              </SideLink>
              <SideLink onClick={handleOpen} to="/auth">
                Iniciar Sesion
              </SideLink>
            </>
          )}
        </SideMenu>
        <SideBtnWrap>
          {isAuth ? (
            <Button onClick={handleLogout}>Cerrar Sesion</Button>
          ) : (
            <Button onClick={() => history.push('/auth/register')}>
              Registro
            </Button>
          )}
        </SideBtnWrap>
      </SideWrapper>
    </SideContainer>
  )
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default Sidebar

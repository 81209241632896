import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { BsChevronDown } from 'react-icons/bs'
import { AiOutlinePoweroff } from 'react-icons/ai'

export const Nav = styled.div`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
`
export const NavWrapper = styled.div`
  height: 70px;
  margin-top: -80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;

  @media screen and (min-width: 1100px) {
    margin: 0 auto;
    margin-top: -80px;
    max-width: 1100px;
  }
`

export const NavContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isAuth }) => (!isAuth ? '200px' : '180px 1fr')};
  justify-content: center;
  z-index: 1;
  padding: 0 35px;
  max-width: 1100px;

  @media screen and (min-width: 960px) {
    width: 100%;
    display: grid;
    grid-template-columns: ${({ isAuth }) =>
      !isAuth ? '200px' : '180px 1fr 270px'};
    justify-content: center;
  }
`

export const NavLogo = styled(LinkR)`
  width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const NavMenu = styled.div`
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const NavMenuItem = styled(LinkR)`
  font-size: 18px;
  cursor: pointer;
  margin-inline: 15px;
  text-decoration: none;
  padding-block: 5px;
  border-bottom: 1px solid
    ${({ to, pathname }) => {
      if (to === pathname) return '#01c794'
      return 'transparent'
    }};
  transition: border 0.3s;

  &:hover {
    border-bottom: 1px solid #01c794;
  }
`

export const NavAuth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const NavSignIn = styled(LinkR)`
  font-size: 18px;
  margin-right: 10px;
  text-decoration: none;
`

export const NavSignUp = styled(LinkR)`
  font-size: 18px;
  border-radius: 5px;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border: 1px solid #9d9d9d;
`

export const NavMobileIcon = styled.div`
  display: block;
  position: absolute;
  top: 25px;
  right: 35px;
  color: #040404;
  font-size: 35px;
  cursor: pointer;

  @media screen and (min-width: 750px) {
    top: 35px;
    right: 35px;
    font-size: 45px;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`

export const NavUserMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const NavUsericon = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
`

export const NavUserName = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  user-select: none;
`

export const NavUserMenuIcon = styled(BsChevronDown)`
  cursor: pointer;
  font-size: 18px;
  margin-left: 20px;
`

export const NavUserDropDown = styled.div`
  position: absolute;
  top: 60px;
  left: 115px;
  width: 160px;
  padding: ${({ isOpen }) => (isOpen ? '0.5rem' : '0 0.5rem')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden;
  background-color: #fff;
  border: ${({ isOpen }) => (isOpen ? '1px solid #e2e2e2' : '')};
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.1);
  max-height: ${({ isOpen }) => (isOpen ? '10em' : '0')};

  transition: all 0.2s ease-in;
`

export const NavUserBtn = styled.button`
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0.5rem;
  border: 0;
  outline: 0;
  border-radius: 5px;
  background: transparent;
`

export const Title = styled.p`
  margin: 0;
  text-align: right;
  margin-top: 1px;
`

export const IconLogOut = styled(AiOutlinePoweroff)`
  cursor: pointer;
  font-size: 18px;
  font-size: 20px;
  margin-right: 5px;
`

import styled from 'styled-components'

export const DragZoneContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px #222
    ${({ isDragActive, disabled }) => {
      if (disabled) return 'solid'
      if (isDragActive) return 'solid'

      return 'dashed'
    }};

  transition: border 0.3s;

  &:focus,
  &:active {
    outline: 0;
  }

  strong {
    margin-block: 1rem;
    text-align: center;
  }

  span {
    text-align: center;
    @media screen and (min-width: 756px) {
      display: none;
    }
  }

  p {
    text-align: center;
    @media screen and (max-width: 756px) {
      display: none;
    }
  }

  svg {
    font-size: 80px;
    color: ${({ color }) => color};
    @media screen and (max-width: 756px) {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 756px) {
    height: 55px;
    display: grid;
    grid-template-columns: 50px 1fr;
  }
`

export const DragZoneWrap = styled.div`
  min-height: 170px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import isLength from 'validator/lib/isLength'

// VALIDAR LOGIN
export const logValid = ({ email, password }) => {
  const errors = { ok: true }
  if (!isEmail(email)) {
    errors.ok = false
    errors.email = { msg: 'Debe colocar un formato de correo correcto' }
  }
  if (isEmpty(password)) {
    errors.ok = false
    errors.password = { msg: 'La contraseña es un campo obligatorio' }
  }
  return errors
}

// VALIDAR REGISTRO VIA EMAIL
export const regValid = ({
  email,
  email2,
  password,
  password2,
  nroDocumento,
  celular
}) => {
  const errors = { ok: true }

  if (!isEmail(email)) {
    errors.ok = false
    errors.email = { msg: 'Debe colocar un formato de correo correcto' }
  }
  if (email !== email2) {
    errors.ok = false
    errors.email = { msg: 'Los correos deben ser iguales' }
    errors.email2 = { msg: 'Los correos deben ser iguales' }
  }
  if (isEmpty(password)) {
    errors.ok = false
    errors.password = { msg: 'La contraseña es un campo obligatorio' }
  }
  if (password !== password2) {
    errors.ok = false
    errors.password2 = { msg: 'Las contraseñas deben ser iguales' }
  }
  if (!/(?=.*[\w]).{8,}/g.test(password)) {
    errors.ok = false
    errors.password = { msg: 'Debe ser mayor a 8 caracteres' }
  }
  if (!isLength(nroDocumento, { min: 5 })) {
    errors.ok = false
    errors.nroDocumento = { msg: 'Debe tener minimo 5 digitos' }
  }
  if (!isLength(celular, { min: 9 })) {
    errors.ok = false
    errors.celular = { msg: 'El telefono debe tener minimo 9 digitos' }
  }
  return errors
}

// VALIDAR REGISTRO VIA GOOGLE O FB
export const authSocialValid = ({ email, nroDocumento, celular }) => {
  const errors = { ok: true }
  if (!isEmail(email)) {
    errors.ok = false
    errors.email = { msg: 'Debe colocar un formato de correo correcto' }
  }
  if (!isLength(nroDocumento, { min: 8 })) {
    errors.ok = false
    errors.nroDocumento = { msg: 'Debe tener minimo 5 digitos' }
  }

  if (!isLength(celular, { min: 9 })) {
    errors.ok = false
    errors.celular = { msg: 'El telefono debe tener minimo 9 digitos' }
  }
  return errors
}

// VALIDAR REGISTRO PASO 2 DATOS PERSONALES
export const infoValid = ({ name, lastName, birthday, motherLastName }) => {
  const errors = { ok: true }
  const actualYear = new Date().getFullYear()
  const userYear = new Date(birthday).getFullYear()

  if (isEmpty(name)) {
    errors.ok = false
    errors.name = { msg: 'El nombre es obligatorio.' }
  }
  if (isEmpty(lastName)) {
    errors.ok = false
    errors.lastName = { msg: 'El Apellido paterno es obligatorio.' }
  }
  if (isEmpty(motherLastName)) {
    errors.ok = false
    errors.motherLastName = { msg: 'El Apellido materno es obligatorio.' }
  }
  if (actualYear - userYear < 18) {
    errors.ok = false
    errors.birthday = { msg: 'Debes ser mayor de edad.' }
  }
  return errors
}

// VALIDAR REGISTRO PASO 3 OCUPACION
export const ocupationValid = ({ ocupacion, personaExpuesta }) => {
  const errors = { ok: true }

  if (isEmpty(ocupacion)) {
    errors.ok = false
    errors.ocupacion = { msg: 'Debes Seleccionar tu ocupacion.' }
  }
  if (personaExpuesta === null) {
    errors.ok = false
    errors.personaExpuesta = { msg: 'Debes seleccionar SI o NO.' }
  }
  return errors
}

// VALIDAR REGISTRO PASO 4 DOCUMENTO
export const documentValid = ({ foto1, foto2, documentCountryExp }) => {
  const errors = { ok: true }
  if (isEmpty(foto1.name)) {
    errors.ok = false
    errors.foto1 = { msg: 'Debes Adjuntar el frontal de tu documento.' }
  }
  if (isEmpty(foto2.name)) {
    errors.ok = false
    errors.foto2 = {
      msg: 'Debes Adjuntar el reverso de tu documento.'
    }
  }
  return errors
}

// Validar creacion de perfil Persona paso 1

export const NewPInfoValid = ({
  celular,
  nroDocumento,
  name,
  lastName,
  motherLastName,
  birthday
}) => {
  const errors = { ok: true }

  const actualYear = new Date().getFullYear()
  const userYear = new Date(birthday).getFullYear()

  if (isEmpty(name)) {
    errors.ok = false
    errors.name = { msg: 'El nombre es obligatorio.' }
  }
  if (isEmpty(lastName)) {
    errors.ok = false
    errors.lastName = { msg: 'El Apellido paterno es obligatorio.' }
  }
  if (isEmpty(motherLastName)) {
    errors.ok = false
    errors.motherLastName = { msg: 'El Apellido materno es obligatorio.' }
  }
  if (actualYear - userYear < 18) {
    errors.ok = false
    errors.birthday = { msg: 'Debes ser mayor de edad.' }
  }
  if (!isLength(nroDocumento, { min: 8 })) {
    errors.ok = false
    errors.nroDocumento = { msg: 'Debe tener minimo 5 digitos' }
  }
  if (!isLength(celular, { min: 9 })) {
    errors.ok = false
    errors.celular = { msg: 'El telefono debe tener minimo 9 digitos' }
  }
  return errors
}

// Validar creacion de perfil Empresa paso 1
export const NewBInfoValid = ({
  ruc,
  alias,
  emailFactura,
  sectorEconomico,
  nombreComercial,
  actividadEconomica
}) => {
  const errors = { ok: true }

  if (isEmpty(ruc)) {
    errors.ok = false
    errors.ruc = { msg: 'El RUC es obligatorio.' }
  }

  if (isEmpty(nombreComercial)) {
    errors.ok = false
    errors.nombreComercial = { msg: 'La Razón social es obligatorio.' }
  }

  if (isEmpty(alias)) {
    errors.ok = false
    errors.alias = { msg: 'Debes asignarle un alias a este perfil.' }
  }

  if (isEmpty(sectorEconomico)) {
    errors.ok = false
    errors.sectorEconomico = { msg: 'Debes seleccionar un sector económico.' }
  }

  if (isEmpty(actividadEconomica)) {
    errors.ok = false
    errors.actividadEconomica = {
      msg: 'Debes seleccionar una actividad económico.'
    }
  }

  if (!isEmail(emailFactura)) {
    errors.ok = false
    errors.emailFactura = { msg: 'Debe colocar un formato de correo correcto' }
  }
  return errors
}

// Validar COD de operacion
export const codOperationValid = ({ nroTransaccion }) => {
  const errors = { ok: true }

  if (isEmpty(nroTransaccion)) {
    errors.ok = false
    errors.nroTransaccion = { msg: 'El Cod. de operacion es obligatorio.' }
  }
  return errors
}

// Validar nueva transaccion
export const newtransactionValid = ({
  cuentaAdmin,
  origenFondos,
  cuentaPerfil
}) => {
  const errors = { ok: true }

  if (!cuentaAdmin.nroCuenta) {
    errors.ok = false
    errors.cuentaAdmin = { msg: 'Este campo es obligatorio.' }
  }
  if (isEmpty(origenFondos)) {
    errors.ok = false
    errors.origenFondos = { msg: 'Este campo es obligatorio.' }
  }
  if (!cuentaPerfil.nroCuenta) {
    errors.ok = false
    errors.cuentaPerfil = { msg: 'Este campo es obligatorio.' }
  }
  return errors
}

// Validar cuenta bancaria
export const bankAccValid = ({
  tipoCuenta,
  banco,
  alias,
  tipoMoneda,
  ownership,
  nroCuenta
}) => {
  const errors = { ok: true }

  if (isEmpty(String(tipoCuenta))) {
    errors.ok = false
    errors.type = { msg: 'Debes elegir el tipo de cuenta.' }
  }
  if (isEmpty(String(banco))) {
    errors.ok = false
    errors.bank = { msg: 'Debes elegir un banco.' }
  }
  if (isEmpty(alias)) {
    errors.ok = false
    errors.alias = { msg: 'Debes colocar un alias a tu cuenta.' }
  }
  if (isEmpty(String(tipoMoneda))) {
    errors.ok = false
    errors.tipoMoneda = { msg: 'Debes elegir la moneda.' }
  }
  if (!ownership) {
    errors.ok = false
    errors.ownership = { msg: 'La cuenta debe ser tuya.' }
  }
  if (isEmpty(nroCuenta.toString())) {
    errors.ok = false
    errors.accountNumber = { msg: 'El numero de cuenta es obligatorio.' }
  }
  return errors
}

// Validar cambio de contraseña
/* eslint-disable */
export const changePassValid = ({ password_nuevo, password_antiguo }) => {
  const errors = { ok: true }

  if (isEmpty(password_nuevo)) {
    errors.ok = false
    errors.password_nuevo = { msg: 'Este campo es obligatorio.' }
  }
  if (isEmpty(password_antiguo)) {
    errors.ok = false
    errors.password_antiguo = { msg: 'Este campo es obligatorio.' }
  }
  return errors
}
/* eslint-enable */

export const updateInfoValid = ({ celular }) => {
  const errors = { ok: true }

  if (isEmpty(celular)) {
    errors.ok = false
    errors.celular = { msg: 'Este campo es obligatorio.' }
  }

  if (!isLength(celular, { min: 8 })) {
    errors.ok = false
    errors.celular = { msg: 'Debe tener minimo 8 digitos.' }
  }
  return errors
}

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Headline,
  Container,
  InputsWrapper,
  SelectWrapper,
  BackError
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons'
import InputText from '../../components/Inputs/InputText'
import InputSelect from '../../components/Inputs/InputSelect'

import useForm from '../../hooks/useForm'
import AuthContext from '../../context/Auth/AuthContext'
import { authSocialValid } from '../../helpers/formValidation'
import { FaTimesCircle } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { CREATE_USER } from '../../apollo/queries'

const RegisterWithSocial = ({ history, location }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const { formValues, setRegAction, setUserAction } = useContext(AuthContext)
  const [state, hOnChange] = useForm({
    email: formValues.email ?? '',
    celular: '',
    nroDocumento: '',
    tipoDocumento: '0',
    codigoRefInvitado: ''
  })

  const [createUser] = useMutation(CREATE_USER)

  useEffect(() => {
    if (!formValues.email) {
      setErrors({
        ok: false,
        backendErr: { msg: 'Ha ocurrido un error intenta iniciar sesión.' }
      })
    }

    if (isDirty) {
      const validation = authSocialValid(state)
      setErrors(validation)
    }
  }, [state])

  const hOnSubmit = async (e) => {
    e.preventDefault()

    setIsDirty(true)
    const validation = authSocialValid(state)
    if (!validation.ok) return setErrors(validation)

    try {
      const GQLData = await createUser({
        variables: { input: location.state }
      })
      console.log(GQLData)
      setUserAction(GQLData.data.CrearUsuario)
    } catch (error) {
      console.log(error)
    }

    setRegAction(state)
    history.push('/auth/register/personalInfo')
  }

  return (
    <Container size="large" center>
      <Headline expand>Registro</Headline>
      <BackError>
        {errors.backendErr && (
          <>
            <FaTimesCircle />
            {errors.backendErr?.msg}
          </>
        )}
      </BackError>
      <Form onSubmit={hOnSubmit}>
        <InputsWrapper>
          <InputText
            name="email"
            type="email"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Correo"
            disabled
          />
          <SelectWrapper>
            <InputSelect
              name="tipoDocumento"
              state={state}
              hOnChange={hOnChange}
            />
            <InputText
              name="nroDocumento"
              type="number"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Número de Documento"
            />
          </SelectWrapper>
          <InputText
            name="celular"
            type="number"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Número de Telefono"
          />
          <InputText
            name="codigoRefInvitado"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="COD Referido (OPCIONAL)"
          />
        </InputsWrapper>
        <Button type="submit" disabled={!errors.ok}>
          Siguiente
        </Button>
      </Form>
    </Container>
  )
}

RegisterWithSocial.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default RegisterWithSocial

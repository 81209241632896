import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import {
  ExchangeHr,
  ExchangeRow,
  ExchangeCol,
  ExchangeFlag,
  ExchangeLabel,
  ExchangeTitle,
  ExchangeAmount,
  ExchangeWrapper,
  ExchangeAction,
  ExchangeContainer,
  ExchangeBtnWrap,
  ExchangeBtn,
  CuponTitle
} from './Exchange.styles'
import InfoPrice from './InfoPrice'
import flagUsa from '../../assets/flag_usa.png'
import flagPeru from '../../assets/flag_peru.png'
import LoadingRings from '../Loading/LoadingRing'

import { exchangeFormat } from '../../helpers/numberFormat'
import { GET_EXCHANGE_RATE } from '../../apollo/queries'
import { Center } from '../App/App.styles'

const Exchange = ({ displayInfo, displayBtn, OnChange, value }) => {
  const { tipoTransaccion, amountToPay, cupon = {} } = value

  // const currentSol = useRef()
  // const currentDolar = useRef()

  const [dolares, setDolares] = useState(0)
  const [soles, setSoles] = useState(amountToPay)
  const [amount, setAmount] = useState(amountToPay)
  // Verificar si el primer campo es sol o dolar
  // tipoTransaccion: 0 = PEN - USD / 1 = USD - PEN
  const [isSolFirst, setIsSolFirst] = useState(tipoTransaccion === 2)
  const { loading, data } = useQuery(GET_EXCHANGE_RATE, {
    pollInterval: 1000 * 60,
    onCompleted: ({ GetMonedaCambio: { exchangeRatePEN } }) => {
      setDolares(exchangeFormat(amountToPay / exchangeRatePEN))
    }
  })

  const hasCupon = Object.keys(cupon).length !== 0
  const exchangeRatePEN = data ? data.GetMonedaCambio.exchangeRatePEN : 0
  const exchangeRateUSD = data ? data.GetMonedaCambio.exchangeRateUSD : 0
  const venta = hasCupon ? exchangeRatePEN - cupon.monto : exchangeRatePEN
  const compra = hasCupon ? exchangeRateUSD + cupon.monto : exchangeRateUSD

  useEffect(() => {
    if (!isSolFirst) {
      setDolares(amount)
      setSoles(exchangeFormat(amount * compra))
    }
    if (isSolFirst) {
      setSoles(amount)
      setDolares(exchangeFormat(amount / venta))
    }

    OnChange({
      tipoTransaccion: isSolFirst ? 2 : 1,
      exchangeRate: isSolFirst ? venta : compra,
      amountToPay: amount,
      amountToBuy: isSolFirst ? amount / venta : amount * compra
    })
  }, [hasCupon, isSolFirst, venta, compra])

  const hSetAmount = () => {
    setIsSolFirst(!isSolFirst)
  }

  const calcular = (e) => {
    const id = e.target.id
    const value = e.target.value
    const exchangeRate = isSolFirst ? venta : compra

    if (id === 'SOL') {
      setAmount(value)
      setDolares(exchangeFormat(value / exchangeRate))
      OnChange({
        amountToPay: isSolFirst ? value : value / exchangeRate,
        amountToBuy: isSolFirst ? value / exchangeRate : value
      })
    } else {
      setAmount(value)
      setSoles(exchangeFormat(value * exchangeRate))
      OnChange({
        amountToPay: isSolFirst ? value * exchangeRate : value,
        amountToBuy: isSolFirst ? value : value * exchangeRate
      })
    }
  }

  // if (!loading) return <LoadingRings />

  return (
    <>
      <ExchangeContainer>
        {loading ? (
          <Center height={300}>
            <LoadingRings />
          </Center>
        ) : (
          <>
            {displayInfo && (
              <InfoPrice
                hasCupon={hasCupon}
                isSolFirst={isSolFirst}
                currentExhangeRate={{ compra, venta }}
                exhangeRate={{ exchangeRatePEN, exchangeRateUSD }}
              />
            )}
            <ExchangeTitle>Cambio</ExchangeTitle>
            <ExchangeCol isSolFirst={isSolFirst}>
              <ExchangeWrapper>
                <ExchangeRow>
                  <ExchangeLabel>Soles</ExchangeLabel>
                </ExchangeRow>
                <ExchangeRow>
                  <ExchangeFlag src={flagPeru} alt="" />
                  <ExchangeAmount
                    id="SOL"
                    type="text"
                    autoComplete="off"
                    value={soles}
                    onKeyUp={(e) => calcular(e)}
                    onChange={({ target }) => setSoles(target.value)}
                  />
                </ExchangeRow>
              </ExchangeWrapper>
              <ExchangeHr onClick={hSetAmount}>
                <ExchangeAction />
              </ExchangeHr>
              <ExchangeWrapper>
                <ExchangeRow>
                  <ExchangeLabel>Dolares</ExchangeLabel>
                </ExchangeRow>
                <ExchangeRow>
                  <ExchangeFlag src={flagUsa} alt="" />
                  <ExchangeAmount
                    id="USD"
                    type="text"
                    autoComplete="off"
                    value={dolares}
                    onKeyUp={(e) => calcular(e)}
                    onChange={({ target }) => setDolares(target.value)}
                  />
                </ExchangeRow>
              </ExchangeWrapper>
            </ExchangeCol>
            {hasCupon && <CuponTitle>Monto aplicado: {cupon.monto}</CuponTitle>}
            {displayBtn && (
              <ExchangeBtnWrap>
                <ExchangeBtn>Cambiar</ExchangeBtn>
              </ExchangeBtnWrap>
            )}
          </>
        )}
      </ExchangeContainer>
    </>
  )
}

Exchange.propTypes = {
  displayInfo: PropTypes.bool,
  displayBtn: PropTypes.bool,
  OnChange: PropTypes.func,
  value: PropTypes.object
}

export default Exchange

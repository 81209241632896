import React, { useEffect, useState } from 'react'
// import AuthContext from '../../context/Auth/AuthContext'
import PropTypes from 'prop-types'

import {
  Form,
  Headline,
  Container,
  InputsWrapper,
  SelectWrapper,
  Select,
  FrontError
} from '../../components/Auth/Auth.styles'
import { Button } from '../../components/Buttons'
import InputText from '../../components/Inputs/InputText'

import useForm from '../../hooks/useForm'
import { NewBInfoValid } from '../../helpers/formValidation'

const NewBusinessProfile = ({ history }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({ ok: true })
  const [state, hOnChange] = useForm({
    ruc: '151515515151',
    nombreComercial: 'GONZALEZ GONZALEZ CRISTIAN ALBERTO',
    alias: 'Empresas',
    emailFactura: 'empresa@empresa.com',
    sectorEconomico: '',
    actividadEconomica: ''
  })

  useEffect(() => {
    if (isDirty) {
      const validation = NewBInfoValid(state)
      setErrors(validation)
    }
  }, [state])

  const hOnSubmit = (e) => {
    e.preventDefault()

    const validation = NewBInfoValid(state)
    setErrors(validation)

    setIsDirty(true)
    if (validation.ok) {
      history.push('/app/profile/businessBankA', state)
    }
  }

  return (
    <Container size="large" center>
      <Headline expand>Nuevo Perfil Empresarial</Headline>
      <Form onSubmit={hOnSubmit}>
        <InputsWrapper>
          <InputText
            name="ruc"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="RUC"
          />
          <InputText
            name="nombreComercial"
            type="text"
            state={state}
            errors={errors}
            hOnChange={hOnChange}
            placeholder="Razon Social"
          />
          <SelectWrapper>
            <InputText
              name="alias"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Nombre del perfil"
            />
            <InputText
              name="emailFactura"
              type="text"
              state={state}
              errors={errors}
              hOnChange={hOnChange}
              placeholder="Email"
            />
          </SelectWrapper>
          <SelectWrapper>
            <div>
              <Select
                name="sectorEconomico"
                value={state.sector}
                onChange={hOnChange}
              >
                <option value="">Seleccione Sector económico</option>
                <option value="0">Agropecuario</option>
                <option value="1">Construcciones</option>
                <option value="2">
                  Manufactura - Procesos de recursos Primarios
                </option>
                <option value="3">Manufactura - Industria No Primaria</option>
                <option value="4">Minería e Hidrocarburos</option>
                <option value="5">Pesca</option>
                <option value="6">Servicios</option>
              </Select>
              <FrontError></FrontError>
            </div>
            <div>
              <Select
                name="actividadEconomica"
                value={state.activity}
                onChange={hOnChange}
              >
                <option value="">Seleccione Actividad económica</option>
                <option value="0">Comercio Automotriz</option>
                <option value="1">Comercio de Arroz</option>
                <option value="2">Comercio al por mayor</option>
                <option value="3">Comercio al por menor</option>
              </Select>
              <FrontError></FrontError>
            </div>
          </SelectWrapper>
        </InputsWrapper>
        <Button type="submit">Siguiente</Button>
      </Form>
    </Container>
  )
}

NewBusinessProfile.propTypes = {
  history: PropTypes.object
}

export default NewBusinessProfile
